export const nl = {
  locale: "nl",
  messages: {
    MISC: {
      formErrRequired: "Een waarde is vereist",
      formErrEmail: "Een email moet worden ingevuld",
      formHintYes: "Ja",
      formHintQuestion: "Gebruik {{ what }}?",
      signedInAs: "Je bent ingelogd als",
      logout: "Log uit",
      youOwner: "Jij",
      closeDialogBtn: "Sluit",
      ariaCloseDialog: "Sluit scherm",
      clearSearch: "Clear search",
      serverErrorDefaultToastMsg: "Server error",
      ienoticeTitle: "Browser niet ondersteund",
      ienoticeTxt:
        "Internet Explorer wordt momenteel niet ondersteund. Gebruik alstublieft een andere browser, zoals Google Chrome of Microsoft Edge",
      ienoticeDismiss: "Begrepen",
      requestFullTranscriptBodyDialog:
        "Als u wilt dat uw tekst wordt geperfectioneerd door onze taalexperts, upload hem dan opnieuw en kies voor het transcriptietype 'perfect'.",
      requestFullTranscriptTitle: "Ontvang 100% accurate tekst",
      requestFullTranscriptBody:
        "Vraag vrijblijvend een offerte aan voor een door onze menselijke transcribenten volledig verbeterd transcript",
    },
    CONFIRM_SUBMIT: {
      title: "Weet je zeker dat je het bestand wilt inleveren?",
      checkbox1:
        "Ik heb de aanvullende notities gecontroleerd en de klantspecifieke vereisten indien nodig geïmplementeerd.",
      checkbox2:
        "De volledige ASR-uitvoer van het bestand is gecontroleerd met de spelling- en grammaticacontrole.",
      checkbox3: "Het transcript is gemaakt volgens de richtlijnen.",
      back: "Breng me terug naar het bestand",
      submit: "Lever het bestand in voor controle",
    },
    TRANSCRIPT_ERROR: {
      message:
        "Je hebt geen toegang meer voor dit bestand. Het bestand is waarschijnlijk herverdeeld. Neem contact op met operations als je vragen hebt.",
      button: "Terug naar dashboard",
    },
    NAV: {
      titleAccount: "Account",
      titleAccountNew: "Voeg een nieuwe gebruiker toe",
      titleAccountMy: "Mijn account",
      titleBilling: "Rekeningen",
      titleInvoices: "Facturen",
      titleCheckout: "Checkout",
      titleCoupon: "Wissel coupon in",
      titleInvite: "Vrienden uitnodigen",
      titleLogin: "Login",
      titlePasswordReset: "Reset wachtwoord",
      titleEmailvalidate: "Email validation",
      titlePlan: "Abonnement",
      titleCosts: "Costs",
      titleRecords: "Jouw ambers",
      titleRecordsTooltip: "Terug naar dashboard",
      titleRegister: "Registeren",
      titleTransactions: "Transacties",
      titleUploads: "Uploads",
      titleDashboard: "Dashboard",
      titleManageUsers: "Gebruikers beheren",
      titleAddUser: "Add user",
      titleManageTranscriptions: "Transcripties beheren",
      tooltipAdmin: "Admin",
      titleAdminUser: "Gebruikers beheren",
      tooltipLanguage: "Kies taal",
      newBadge: "nieuw",
      help: "Help",
      currentPlanFallback: "Upgrade naar een abonnement",
      currentPlanTooltip: "Huidig abonnement",
    },
    LANGUAGES: {
      en: "Engels",
      nl: "Nederlands",
      de: "Duits",
      fr: "Frans",
      es: "Spaans",
      it: "Italiaans",
      pt: "Portugees",
    },
    ACCOUNT: {
      titleForm: "Account Details",
      username: "Gebruikersnaam",
      email: "Email",
      firstName: "Voornaam",
      lastName: "Achternaam",
      company: "Bedrijf",
      pwd: "Wachtwoord",
      newPwd: "Nieuw wachtwoord",
      confirmPwd: "Bevestig wachtwoord",
      changePwd: "Verander wachtwoord",
      createPwd: "Maak wachtwoord aan",
      msgUsernameMaxlength:
        "Je naam moet langer dan {{ quantity }} karakters zijn.",
      msgPwdLength:
        "Je wachtwoord moet langer dan {{ quantity }} karakters zijn.",
      msgPwdPattern:
        "Het wachtwoord moet minimaal één cijfer, één hoofdletter, één kleine letter en één symbool bevatten.",
      msgPwdConfirm: "Je nieuwe wachtwoord moet bevestigd worden",
      msgPwdMismatch: "Moet gelijkstaan aan eerdere invoer",
      loading: "Details aan het laden...",
      update: "Update data",
      updating: "Data aan het opslaan...",
      save: "Sla gebruiker op",
      saving: "Sla gebruiker op...",
    },
    ADMIN: {
      add: {
        donateSeconds: "Donate seconds",
      },
      dashboard: {
        loading: "Dashboard laden...",
        noResults: "Geen dashboard elementen beschikbaar",
      },
      transactions: {
        id: "ID",
        owner: "Eigenaar",
        seconds: "Seconden",
        date: "Datum",
        noId: "Geen ID gespecificeerd",
        loading: "Bezig met het laden van je transacties ...",
        noResults: "Geen transacties",
        results: "Transcripties gevonden:",
      },
      transcriptions: {
        created: "Aangemaakt",
        id: "ID",
        name: "Name",
        user: "Gebruiker",
        status: "Status",
        length: "Length",
        type: "Type",
        loading: "Laden transcripties...",
        noResults: "Geen transcripties aanwezig",
        results: "Transcripties gevonden:",
      },
      users: {
        created: "Aangemaakt",
        username: "Gebruikersnaam",
        fullname: "Volledige naam",
        status: "status",
        minutes: "Audio tijd",
        pause: "Pauzeer gebruiker",
        activate: "Activate user",
        delete: "Verwijder gebruiker",
        subscribed: "Subscribed",
        loading: "Gebruikers laden...",
        noResults: "Geen gebruikers om te tonen",
        results: "Gebruikers gevonden:",
        deleteConfirm: "Weet je zeker dat je  '{{ name }}' wilt verwijderen?",
        deleteOk: "Yes",
        deleteCancel: "Annuleer",
        search: "Search users",
      },
      user: {
        navBackToUsers: "Alle Gebruikers",
        navEdit: "Profiel",
        navTranscriptions: "Transcripties",
        navTransactions: "Transacties",
        navDonate: "Donate",
        fullName: "Volledige naam",
        availableSeconds: "Beschikbare tijd",
        created: "Aangemaakt op",
        isSubscribed: "Huidig abonnement",
        isntSubscribed: "Geen abonnementen",
        userType: "Type",
        status: "Status",
        language: "Preferred language",
        makeAdmin: "Admin mogelijkheden",
        cancelSubscription: "Cancel subscription",
        cancellingSubscription: "Cancelling...",
      },
      donate: {
        title: "Donate hours",
        username: "Recipient's username",
        seconds: "Number of seconds",
        secondsFormatted: "Hours : minutes : seconds",
        comment: "Message to recipient",
        submit: "Send",
        submitting: "Sending...",
      },
      sendvalidationemail: {
        submit: "Send validation email",
        submitting: "Sending",
      },
    },
    BILLING: {
      titleForm: "Rekening details",
      firstName: "Voornaam",
      lastName: "Achternaam",
      email: "Email",
      phone: "Telefoon",
      company: "Bedrijf",
      vatNumber: "BTW nummer",
      address: "Adres",
      address2: "Adres 2",
      city: "Stad",
      country: "Land",
      zipCode: "Postcode",
      state: "Staat",
      loading: "Details aan het laden...",
      save: "Sla details op",
      saving: "Sla details op...",
    },
    CHECKOUT: {
      choose: "1. Kies je abonnement",
      setup: "2. Stel je betaling in",
      confirm: "3. Bevestiging",
      loadingStripe: "stripe aan het laden...",
      titleCard: "Card Details",
      titleBilling: "Rekening details",
      titleSummary: "Samenvatting van je order",
      subscribe: "Aboneer je op",
      subscribing: "Inschrijven...",
      info: "Amberscript accepteert de volgende creditcards en bankpassen. Alle betalingen zijn beveiligd door Stripe.",
      labelNameOnCard: "Naam op kaart",
      labelCard: "Creditcard of bankpas",
      termsDisclaimer: "By continuing you agree to Amberscript's",
      termsDisclaimerLinkTxt: "Algemene Voorwaarden",
    },
    SUMMARY: {
      plan: "Abonnement:",
      cost: "Kosten:",
      beyond: "Tot",
      hrs: "uren",
      hour: "uur",
      billingCycle: "Betalingscyclus:",
      billingInterval: "maandelijks",
      total: "Totaal:",
      loading: "Laden van een samenvatting...",
      error: "Het laden van de samenvatting is niet gelukt :(",
    },
    COSTS: {
      titlePlan: "Huidig abonnement",
      titleCosts: "Samenvatting",
      titleInvoices: "Factuur overzicht",
      noResultsPlan: "Upgrade naar een abonnement",
      noResultsCosts: "Je hebt geen kosten gemaakt deze maand",
      noResultsInvoices: "Je hebt nog geen facturen.",
      loadingSummary: "Samenvatting aan het laden...",
      loadingInvoices: "Facturen laden...",
      summary:
        "Deze maand heb je {{ time }} gebruikt voor in totaal {{ cost }} euro.",
      remainder:
        "Met jouw abonnement {{ planName }} betaal je {{ price }} per uur",
      remainderB: "Je hebt {{ num }} uren inclusief.",
      remainderC1: "en een vast bedrag van {{ cost }} per {{ interval }}.",
      remainderC2: "en geen vaste kosten.",
      INVOICES: {
        invoiceNr: "Nr",
        created: "Datum",
        description: "Beschrijving",
        monthPeriod: "Maand",
        yearPeriod: "Jaar",
        nettoAmount: "Netto",
        vatAmount: "BTW",
      },
    },
    COUPON: {
      title: "Wissel coupon in",
      inputLabel: "Coupon code",
      submit: "Sturen",
      submitting: "Wordt verzonden...",
      cancel: "Annuleer",
      invalidCouponCode: "Ongeldige coupon code",
    },
    EDITOR: {
      tooManyRows: "Te veel rijen",
      busy: "Bezig met laden ...",
      feedbackBtn: "Feedback geven",
      shortcutsBtn: "Laat alle shortcuts zien",
      shortcutsHint: "Afspelen of pauzeren? Gebruik Control + Spatie",
      shortcutHintAltClick:
        "Wil je de audio automatisch laten verspringen wanneer je klikt? Houdt alt ingedrukt tijdens het klikken",
      shortcutHintNewLine:
        "Wil je een nieuwe regel in een segment? Typ shift + enter",
      shortcutsTitle: "Keyboard Sneltoetsen",
      ariaShortcutsClose: "Sluit sneltoetsen",
      replayTour: "Replay Tour",
      errorEmptySegments:
        "Amber herkende geen woorden in de taal die je geselecteerd hebt. Ga alsjeblieft even na of er gesproken tekst zit in de audio en of je genoeg credits hebt. We zijn altijd bereikbaar voor vragen!",
      errorLoadAudio: "Er is iets misgegaan bij het laden van het bestand",
      errorLoadXML: "Er is iets misgegaan bij het laden van het bestand",
      errorReadAudio: "Er is iets misgegaan bij het laden van het bestand",
      loadingAudio: "Audio laden...",
      loading: "Laden van het transcript...",
      parsing: "Transcript aan het parsen...",
      speakerClear: "Verwijder spreker",
      speakerAddBtn: "Toevoegen",
      speakerAddPlaceholder: "Spreker toevoegen",
      speakerAddTitle: "Spreker toevoegen",
      speakerEditTitle: "Verander spreker",
      findreplTitle: "Zoek en vervang",
      findreplFindLabel: "Vinden:",
      findreplReplLabel: "Vervang met:",
      findreplReplBtn: "Vervang alles",
      infoTitle: "Transcriptie informatie",
      infoClose: "Sluit transcriptie informatie",
      infoNameLabel: "Naam",
      infoDateLabel: "Datum",
      infoCancel: "Annuleer",
      infoSave: "Opslaan",
      infoSaving: "Opslaan...",
      feedbackTitle: "Feedback",
      feedbackRatingLabel: "Hoe goed was de kwaliteit van het transcript?",
      feedbackCommentLabel: "Bericht achterlaten",
      feedbackSubmit: "Send",
      feedbackSubmitting: "Sending...",
      feedbackVal1: "Slecht",
      feedbackVal2: "Redelijk",
      feedbackVal3: "Oke",
      feedbackVal4: "Goed",
      feedbackVal5: "Heel goed",
      exportBtn: "Exporteren",
      exportXML: "XML",
      exportWord: "Word",
      autosaveSuccess: "Alle veranderingen opgeslagen",
      autosavePending: "Opslaan...",
      autoSaveSaved: "Opgeslagen",
      autoSaveError: "Fout bij het opslaan",
      autoSaveTryAgain: "Klik hier om het opnieuw te proberen",
      metaCreated: "Gecreëerd",
      metaLength: "Lengte",
      metaStartTimeOffset: "Starttijd",
      metaStartTimeOffsetLabel: "Starttijd (hh:mm:ss:ms)",
      metaStartTimeOffsetTitle: "De starttijd opnieuw instellen",
      metaStartTimeOffsetDescription:
        "Hier kan je de starttijd van de tijdscodes instellen om te synchroniseren met de originele bron. Voer het aantal seconden in die moeten worden toegevoegd aan alle originele tijdscodes van het transcript",
      metaStartTimeOffsetSubmit: "Opslaan",
      metaWords: "Woorden",
      metaPlaybackRate: "Snelheid",
      metaPlaybackRateTip: "Veranderd afspeelsnelheid",
      controlsTranslation: "Vertaling toevoegen",
      addNewLanguage: "Vertaling toevoegen",
      controlsSpellCheck: "Toggle de spelling checker",
      controlsSpellCheckShortcut: "(ctrl+g)",
      controlsReplace: "Vervangen",
      controlsReplaceShortcut: "(ctrl+f)",
      controlsUndo: "Ongedaan maken",
      controlsUndoShortcut: "(ctrl+z)",
      controlsRedo: "Herstellen",
      controlsRedoShortcut: "(ctrl+shift+z)",
      controlsHighlight: "Highlighten",
      controlsHighlightShortcut: "(ctrl+h)",
      controlsSubtitles: "Ondertitels",
      controlsSubtitlesShortcut: "Converteren naar ondertitels",
      rewindBtn: "Terugspoelen",
      resumeBtn: "Hervatten",
      ariaPlay: "Afspelen",
      ariaPause: "Pauzeren",
      blockTextLength: "{textLength} tekens",
      adjustStartTimestampHeader: "Pas starttijdstempel aan",
      adjustEndTimestampHeader: "Pas eindtijdstempel aan",
      adjustTimestampDescription:
        "Minimale en maximale tijdstempels worden bepaald door de tijdstempels van de vorige en volgende woorden.",
      adjustDialogSubmit: "Aanpassen",
      adjustDialogCancel: "Annuleer",
      subtitlesDialogHeader: "Converteer naar ondertitels",
      subtitlesDialogDescription:
        "Converteer uw transcript automatisch naar voor de ondertitel geschikte zinnen. Amberscript splitst de tekst op in kleinere frases die op het scherm passen.",
      subtitlesDialogNote:
        "Opmerking: Deze actie kan niet automatisch worden teruggedraaid. Uw tekst kan echter nog steeds worden bewerkt.",
      subtitlesDialogAdvancedOptions: "Geavanceerde opties",
      subtitlesDialogSubmit: "Converteren",
      subtitlesDialogSubmitLoading: "Even geduld aub",
      subtitlesDialogCancel: "Annuleer",
      noEditingForMobileOrTablet:
        "Momenteel ondersteunt Amberscript geen bewerking op uw apparaat. Amberscript werkt het best met een laptop of PC in combinatie met Google Chrome en ondersteunt tevens Microsoft Edge, Apple Safari en Mozilla Firefox.",
      emailValidationTitle: "E-mail is nog niet gevalideerd",
      emailValidationDescription:
        "Bevestig alsjeblieft jouw e-mail om jouw transcript te exporteren of te kopiëren.",
      btnAlreadyActivated: "Ik heb mijn e-mail al geactiveerd",
      btnSendActivationAgain: "Verificatie e-mail opnieuw sturen",
      btnActivationSent: "E-mailverificatie verzonden!",
      alertLoggedOutTitle: "Login vereist",
      alertLoggedOutDescription:
        "Je bent uitgelogd in een ander tabblad. Gelieve je terug aan te melden bij Amberscript.",
      alertLoggedOutButton: "Ga naar log in",
      btnApproveOrReject: "Goedkeuren of afwijzen...",
      btnDetails: "Details",
      dialogJobFeedback: {
        btnApprove: "Goedkeuren",
        btnReject: "Afwijzen",
        title: "Kwaliteitscontrole goedkeuren of weigeren",
        rateTranscriber:
          "Becijfer {email} op dit bestand, 1 als laagst, 5 als hoogst. Hulp nodig? Lees de ",
        qualityControlGuidelines: "Quality Control gids.",
        textAreaPlaceholder: "Voeg notities toe...",
        notesExplanation:
          "Vereist: notities toevoegen, zelfs bij het goedkeuren, dus de transcriber kan uw feedback zien.",
        transcription: "Tekst",
        syntax: "Richtlijnen",
        grammar: "Spelling/Tikfouten/Grammatica",
        punctuation: "Interpunctie/Syntax",
        research: "Onderzoek (namen en jargon)",
        difficulty: "Moeilijkheidsgraad",
        difficultyExplanation:
          "Deze score maakt geen deel uit van de totale scoreberekening.",
        total: "Total Transcription Rating",
        tooltip: {
          transcription:
            "Er wordt gecontroleerd of de gesproken tekst correct is omgezet in geschreven tekst. Dit betekent het nauwkeurig omzetten van woorden, zinnen en uitspraken zonder fouten of missende woorden, volgens de richtlijnen. Er wordt gecontroleerd of er luisterfouten zijn die tot een onjuiste interpretatie van de transcripties kunnen leiden. (30%)",
          syntax:
            "Hier wordt gecontroleerd of de transcriptie is uitgevoerd volgens de vastgestelde richtlijnen. Dit omvat het markeren van onbegrijpelijke passages, het weergeven van sprekers, het meenemen van pauzes en zinsbreuken en andere richtlijnen die van toepassing zijn op de transcriptie. Alle speciale richtlijnen worden bijzonder strikt beoordeeld. Er moet worden gecontroleerd of de opmaak van het transcript correct is. Dat gaat over onder andere de regelafstand, lettergrootte, rangschikking van tekstelementen en andere aspecten van de visuele presentatie van het transcript. (25%)",
          grammar:
            "In deze categorie worden grammaticale fouten en spelling- en typfouten in het transcript beoordeeld. Het doel is ervoor te zorgen dat de tekst zowel grammaticaal correct is als vrij van spelfouten volgens de richtlijnen. Dit omvat het controleren op fouten zoals verkeerd gespelde woorden, ontbrekende hoofdletters en fouten bij het gebruik van afkortingen. (25%)",
          punctuation:
            "Er wordt gecontroleerd of interpunctietekens zoals punten en komma’s correct zijn gebruikt om de leesbaarheid en begrijpelijkheid van de tekst te verbeteren. Ook wordt beoordeeld of het gebruik van aanhalingstekens gepast is en of andere interpunctietekens correct zijn gebruikt. Een ander aspect van deze categorie is de correcte verdeling van lange zinnen. (15%)",
          research:
            "Deze categorie richt zich op de beoordeling van het onderzoek naar technische termen, eigennamen en andere gespecialiseerde uitdrukkingen die in het transcript voorkomen. Er wordt gecontroleerd of deze termen correct zijn gespeld en gebruikt en, indien beschikbaar, of termen uit een woordenlijst zijn gebruikt. (5%)",
        },
      },
      SUBTITLE: {
        sliderShorterLabel: "Korter",
        sliderLongerLabel: "Langer",
        sliderRecommendedLabel: "Aanbevolen",
        slidercharsPerSecLabel: "{charsPerSec} tekens / sec.",
      },
      EXPORT: {
        title: "Tekst exporteren",
        format: "Formaat",
        export: "Exporteren",
        optionsTitle: "Export opties",
        optionsTimestamps: "Voeg tijdcodes toe",
        optionsSpeakers: "Voeg sprekers toe",
        optionsIncludeHighlights: "Met highlights exporteren",
        optionsHighlights: "Alleen highlights exporteren",
        maxCharsPerRow: "Maximaal aantal lettertekens per rij",
        milliseconds: "Milissegundos",
        fps: "FPS",
        cancel: "Annuleer",
        ok: "Export",
        transcribedByAmberScript:
          "Deze spraak-naar-tekst is gecreëerd met www.amberscript.com",
        wayOfExport: "Hoe wil je je bestand exporteren?",
        text: "Tekst",
        textDesc:
          "Word document of tekst. Perfect voor transcripten van interviews, meetings en media tagging.",
        subtitleDesc:
          "SRT, VTT of EBU-STL. Handig voor social media, Youtube, Videoplayers en meer.",
        video: "Video",
        videoDesc:
          "Download video met of zonder ondertiteling. Voor social media is het handig als de ondertiteling al is ingebrand.",
        rules: "Regels",
        burnSubToVideo: "Brand ondertiteling op video - Komt binnenkort",
        subtitleRule: "Max. {maxLine} regels, {maxChar} karakters per regel",
        realignRecommendation:
          "We raden aan om de tijdmarkeringen opnieuw uit te lijnen voordat je exporteert.",
        realignRecommendationDesc:
          "Het bewerken van uw transcript betekent dat de audio opnieuw moet worden uitgelijnd om overeen te komen met uw bewerkingen.",
        realignRecommendation1: "Snel",
        realignRecommendation1Desc:
          "Snelle en algemene herschikking. Kan niet 100% accuraat zijn.",
        realignRecommendation2: "Volledig - Komt binnenkort",
        realignRecommendation2Desc:
          "Een grondige, nauwkeurige heruitlijning van uw audio. Dit kan enkele minuten duren.",
        realignRecommendation3: "Exporteren zonder heruitlijning",
        realignRecommendation3Desc:
          "Je tijdmarkeringen zijn misschien niet nauwkeurig.",
        emailNotification:
          "Je ontvangt een email als je bestand klaar is om te downloaden.",
        options: "Opties",
        whatIsJson:
          "JSON is een data format die gebruikt kan worden voor applicaties.",
        audio: "Audio",
        audioDesc: "Download het originele bestand",
        downloadAudio: "Download Audio",
        downloadVideo: "Download Video",
        exportSubtitleTitle: "Exporteren Ondertitels",
        custom: "Op maat",
        maxNrRows: "Maximaal aantal rijen",
        maxCharPerRow: "Maximaal aantal karakters per rij, inclusief spaties",
        titleTranscript: "Transcript exporteren",
        downloadFile: "Audio bestand downloaden",
        asAFile: "Als een ondertitelbestand",
        asAFileDesc:
          "SRT, VTT of EBU-STL. Kan gebruikt worden op social media, Youtube, video spelers en meer.",
        download: "Downloaden",
        asAVideoWithBurnIn: "Als een videobestand met ingebrande ondertitels",
        asAVideoWithBurnInDesc:
          "Ondertitels komen permanent op de video te staan. Exporteer als mp4, etc.",
        createNewVersion: "Maak een nieuwe versie",
        lastExport: "Laatste export:",
        additionalOptions: "Extra opties",
        downloadAsText: "Als tekst downloaden",
        downloadAudioFile: "Audio bestand downloaden",
        downloadVideoFile: "Video bestand downloaden",
        postVideoOnYoutube: "Video op youtube plaatsen",
        intelligentExport: "Slimme export",
        summary: "Samenvatting",
        summaryTooltip: "Maak een samenvatting van je transcriptie",
        anonymization: "Anonimisering",
        anonymizationTooltip:
          "Anonimiseer alle persoonlijke informatie (namen, adressen. etc.) in je transcriptie.",
        cleanRead: "Clean Read",
        cleanReadTooltip:
          "Maak een transcriptie die is bewerkt voor een betere leesbaarheid.",
        hint: "Let op: voor deze functionaliteit verlaat data het Amberscript-platform en de EU.",
      },
      BURN_IN: {
        processingVideo: "Video verwerken",
        confirmationTitle: "Ben je helemaal tevreden?",
        confirmationText:
          "Je kunt deze versie van je bestand maar één keer inbranden. Voor de aanpassingen die je hierna aan de ondertitels maakt zul je een extra burn-in moeten kopen.",
        continue: "Ja, doorgaan",
        back: "Nee, terug naar bewerken",
        successTitle: "Bestelling geslaagd",
        successText1:
          "Het zal even duren om hem te verwerken. Zodra hij voltooid is, krijg je een bericht en kun je de video downloaden met de",
        successText2: "knop in het dashboard, of in de editor.",
        export: "Export",
        returnToEditor: "Terug naar de editor",
        continueToDashboard: "Verdergaan naar het dashboard",
        notAvailableText: "Wil je een extra export maken?",
        notAvailableLink: "Neem contact op met de klantenservice",
        notConverted:
          "Zet je bestand eerst om in ondertitels voordat je verder gaat met het inbranden.",
      },
      toast: {
        onCopy: "Copying, pasting and cutting is not available.",
        onCut: "Copying, pasting and cutting is not available.",
        onPaste: "Copying, pasting and cutting is not available.",
      },
      RPT: {
        title: "Laat ons de correcties voor je doen",
        description:
          "Door de handmatige transcriptiediensten aan te vragen, krijgen onze taalexperts toegang tot je transcript. Je huidige aanpassingen aan het bestand worden hersteld en het bestand zal op slot gaan totdat de correcties voltooid zijn.",
        description2: "Klik op OK om door te gaan naar betalen",
      },
      BANNER: {
        firstStepTitle: "Check en verbeter je transcript.",
        firstStepDescTranscriber:
          "Je kan dit overslaan, maar er kunnen fouten in je ondertitels zitten.",
        firstStepDesc:
          "Je kan dit overslaan, maar er kunnen fouten in je ondertitels zitten. Je kan ook je ondertitels laten controleren door één van onze taalexperts.",
        secondStepTitle:
          "Klaar met bewerken? Klik op Maak ondertitels om deze te synchroniseren en te formatteren.",
        secondStepDesc:
          "Je kan ook het transcript opslaan voor je administratie.",
      },
      PRE_QC: {
        submitBtn: "Indienen voor kwaliteitscontrole...",
        dialogTitle: "Kwaliteitsassistent",
        submitAnyway: "Alsnog door gaan met indienen",
        viewFix: "Bekijken & Corrigeren",
        spelling: " spellingsfouten",
        grammar: " grammaticafouten",
        cleanread: " clean-read-fouten",
        inaudible: " onhoorbaar-tags",
        cancel: "Annuleren",
        submit: "Indienen",
        confirmationTitle: "Indienen voor kwaliteitscontrole",
        confirmation:
          "Je staat op het punt de job in te dienen voor kwaliteitscontrole. Deze stap kan niet ongedaan worden gemaakt. Weet je zeker dat je het transcript wilt indienen?",
        emptyState: "Ontvang snel feedback op je document.",
        runPreQCBtn: "Kwaliteitsassistent uitvoeren",
        spellingIssueFound: " spellingsprobleem gevonden",
        spellingIssuesFound: " spellingsproblemen gevonden",
        grammarIssueFound: " grammatica probleem gevonden",
        grammarIssuesFound: " grammatica problemen gevonden",
        cleanreadIssueFound: " clean read probleem gevonden",
        cleanreadIssuesFound: " clean read problemen gevonden",
        inaudibleIssueFound: "'onhoorbaar' tag gevonden",
        inaudibleIssuesFound: "'onhoorbaar' tags gevonden",
        noSuggestion: "Geen suggestie",
        loadingSuggestions: "suggesties laden...",
        noIssueNotice: "Geen problemen gevonden. Goed gedaan!",
        formatting: " stijlfouten",
        formattingIssueFound: " probleem met de opmaak",
        formattingIssuesFound: " probleem met de opmaak",
        reportTitle: "Rapporteer fout-positief",
        reportBtn: "Rapporteer fout-positief",
        reportComment: "Opmerking",
        reportCommentPlaceholder: "Voeg hier een opmerking toe",
        spellingInfoTooltip:
          "De spelling van sommige woorden kan incorrect zijn. Overweeg om ze te corrigeren of rapporteer false positives door op het vlag icoon te klikken.",
        inaudibleInfoTooltip:
          "Deze sectie was gemarkeerd als onverstaanbaar. Gebruik deze tag alleen maar wanneer het niet duidelijk is wat er wordt gezegd.",
        grammarInfoTooltip:
          "De tekst bevat een gramaticafout. Overweeg om hoofdletters aan het begin van zinnen te checken.",
        cleanReadInfoTooltip:
          "De tekst voldoet niet aan de clean read richtlijnen. Overweeg om herhaalde woorden, niet toegestaande tags, en straattaal te verwijderen.",
        formattingInfoTooltip:
          "De tekst voldoet niet aan de ondertitelingsrichtlijnen. Overweeg om de CPL/CPS limieten aan te passen, niet toegestaande symbolen te verwijderen en de tekst te verkorten.",
        confirmationMessage:
          "Opmerking: Vanwege het aantal van {totalIssues} mogelijke fouten heeft de Kwaliteitsassistent meer tijd nodig. Weet je zeker dat je wilt doorgaan?",
      },
    },
    CREATE_SUBTITLES: {
      title: "Help ons algoritme om zijn beste werk te doen",
      title1:
        "We gebruiken machine learning om ondertitels te synchroniseren met de audio in een leesbare format.",
      title2:
        "Deze automatisering betekend dat je minder tijd kwijt bent and het verbeteren van de synchronisatie en formattering van je ondertitels.",
      title3:
        "Het corrigeren van fouten in het transcript, voor dat de ondertitels worden gemaakt leidt to nauwkeurigere ondertitels!",
      note1:
        "Alle ondertitel bestanden in andere talen dan Engels, Duits en Nederlands, die langer dan 10 minuten zijn kunnen het beste eerst worden opgegeven naar Operations voor synchronisatie!",
      note2:
        "Zodra je het bestand omzet naar ondertitels, zal je transcriptie niet meer beschikbaar zijn. Exporteer het transcript nu, als je het later nog nodig hebt.",
      note3:
        "Het bestand zal tijdelijk verdwijnen uit je opdrachten overzicht en verschijnt weer zodra deze helemaal geconverteerd is.",
      note4:
        "Het duurt een aantal minuten voordat ons algoritme de ondertitels hebben geformatteerd.",
      note5: "We laten je weten wanneer ze klaar zijn.",
      confirmation1: "Ik ben klaar met het controleren van mijn transcript",
      rule1:
        "Maximaal {maxLinesPerSegment} regel(s), {maxCharsPerLine} tekens per regel, en {maxDisplayTimePerLine} seconden schermtijd",
      rule2: "Maximum aantal regels",
      rule3: "Maximum aantal tekens per regel (TPR)",
      rule5: "Maximale schermtijd per regel",
      customRules: "Aangepaste regels",
      customRulesDescription:
        "CPL, CPS, Min/Max duur, Regels per ondertitel, Frameafstand, Tellen",
      includeSpacesInCount: "Spaties opnemen in karaktertelling",
      includeSpecialCharactersInCount:
        "Aantal tekens berekenen inclusief speciale tekens (#,&,%..)",
      maxCharsPerSecond: "Maximale tekens per seconde",
      minSubtitleDuration: "Minimale ondertitelduur",
      maxSubtitleDuration: "Maximale ondertitelduur",
      minFrameGap: "Minimale frameafstand",
      netflixRules: "Netflix regels",
      msgSuccess1:
        "Het process is succesvol gestart, je wordt terugverwezen naar het platform.",
      msgSuccess2:
        "Het process is succesvol gestart, je wordt terugverwezen naar het dashboard.",
      error1: "Onbekende fout, probeer het later opnieuw",
      button1: "Maak ondertitels",
    },
    OriginalText: {
      title: "Origineel tonen",
      transcript: "Transcript",
    },
    SPELLCHECK: {
      title: "Hoe zet ik de spelling checker aan?",
      clickHere: "Klik hier",
      linkDesc:
        "voor uitleg over hoe je de spellingscheck kunt aanzetten in Google Chrome",
      step1: "Ga naar Chrome instellingen",
      step2: "Klik op Geavanceerde Instellingen",
      step3: "Klik op talen",
      step4: "Open deze link in een nieuwe Chrome tab",
      step5: "Kies de juiste taal",
      step6: "Start Chrome opnieuw op",
    },
    LOGIN: {
      title: "Welkom terug",
      subtitle: "Log in account",
      alreadyQuestion: "Nieuw?",
      alreadyAction: "Registreer nu.",
      btn: "Login",
      emailOrUsername: "Email of gebruikernaam",
      loginFormDescription:
        "Je bent uitgelogd of hebt geen toegang tot deze pagina. Log nogmaals in met het juist account.",
      errorLogin: "Ongeldige combinatie van email en wachtwoord",
      forgotPassword: "Wachtwoord vergeten?",
      loggedinActionBtn: "Ga naar mijn opnamens",
    },
    PASSWORDRESET: {
      alreadyQuestion: "Hulp nodig?",
      alreadyAction: "Contact us at",
      actionLogin: "Log in",
      actionRegister: "Registreer",
      actionOr: "of",
      loggedinActionBtn: "Go to my records",
    },
    PASSWORDRECOVER: {
      title: "Wachtwoord vergeten?",
      subtitle: "Maak hier een wachtwoord aan.",
      btn: "Reset mijn wachtwoord",
      hintUsername:
        "Dit is de gebruikersnaam waarmee je een Amberscript account hebt aangemaakt.",
      or: "of",
      hintEmail:
        "Dit is het emailadres dat je gebruikte toen je jouw Amberscript account hebt aangemaakt.",
    },
    PASSWORDCHANGE: {
      title: "Wijzig uw wachtwoord",
      subtitle: "Maak een wachtwoord aan om terug in te loggen op Amberscript",
      btn: "Bevestig mijn wachtwoord",
    },
    EMAILVALIDATE: {
      loading: "Validatie bezig...",
      inviteTitle: "Bedankt voor uw registratie.",
      inviteSubtitle:
        "Er is een email naar je verzonden om uw account te activeren",
      successTitle: "Gedaan!",
      successSubtitle:
        "Bedankt voor het valideren van uw email. Uw account is nu actief.",
      errorTitle: "Er is iets misgegaan met deze emailvalidatie-link.",
      errorSubtitle: "Als dit probleem blijft optreden, neem contact op met",
      loggedinActionBtn: "Ga naar mijn opnamens",
    },
    PLAN: {
      hour: "uur",
      select: "Selecteer",
      unsubscribeBtn: "Uitschrijven",
      unsubscribeMsg:
        "Weet je zeker dat je wilt uitschrijven voor abonnement {{ plan }}?",
      unsubscribeOk: "Ga verder",
      unsubscribeCancel: "Annuleer",
      yourCurrent: "Jouw huidige abonnement",
      vatDisclaimer: "Prijzen excl. BTW",
      loading: "Abonnementen laden...",
      noResults: "Er zijn geen abonnementen beschikbaar op dit moment",
      showPlans: "Toon abonnementen",
    },
    RECORDS: {
      upload: "Upload",
      name: "Naam",
      words: "Woorden",
      length: "Lengte",
      owner: "Eigenaar",
      lastModified: "Laatst aangepast",
      created: "Gecreëerd",
      more: "Meer",
      ariaMore: "Open acties menu",
      share: "Delen",
      delete: "Verwijder",
      deleting: "Verwijderen...",
      deletedSuccess: "{{ recordId }} succesvol verwijderd.",
      slicedFileMsg:
        "This audio file has been sliced because of insufficient credits left.",
      invalid: "Verkeerd bestand",
      errorGeneric: "Er lijkt iets mis te zijn met deze transcriptie.",
      loading: "Bezig met het laden van jouw transcripties...",
      noResults: "Nog geen transcripten gecreëerd",
      tipUploading: "Uploading...",
      tipOpen: "Ingepland voor transcriptie",
      tipBusy: "Transcriberen...",
      tipDone: "Klaar",
      tipError: "Broken",
      scheduled:
        "{{ audioName }} is ingepland voor transcriptie. Dit kan enkele minuten duren.",
      transcribing: "Transcriberen {{ audioName }}, {{ progress }}...",
      transcribed: "Transcriptie van {{ audioName }} is ready.",
      uploadErrorServer:
        "Server error. Er is een fout opgetreden bij het uploaden van {{ audioName }}.",
      uploadError: "Upload van {{ audioName }} niet gelukt.",
      uploadProgress: "Upload van {{ audioName }}:",
      uploadStart: "Begonnen met uploaden van {{ audioName }}.",
      uploaded: "Het uploaden van {{ audioName }} is voltooid.",
      uploadhelpTitle: "Uploading op Amberscript",
      uploadhelpTxt: "Amberscript ondersteunt de volgende audio types:",
      uploadUnsupprtedFormatTitle: "Bestandsformaat niet ondersteund",
      uploadUnsupprtedFormatMsg:
        "Je dient een audio bestand te selecteren. Ondersteunde formaten:",
      uploadUnsupprtedFormatBtn: "Begrepen",
      uploadConfirmTitle: "Klaar om te transcriberen",
      uploadConfirmMsgTop:
        "Er zijn een aantal factoren die ervoor kunnen zorgen dat jouw interviews minder goed vertaald worden. Wij gaan ervan uit dat jouw bestanden:",
      uploadconfirmList1: "Duidelijk gesproken stemmen bevatten",
      uploadconfirmList2: "Geen (te) hinderlijk achtergrond geluid hebben",
      uploadconfirmList3: "Sprekers hebben die geen zwaar accent hebben",
      uploadConfirmMsgBottom:
        "Als één (of meerdere) bovenstaande criteria niet voldoet kan Amberscript geen hoogwaardige transcripten garaderen.",
      uploadconfirmSelectASR: "Select transcription language",
      uploadConfirmOk: "Verder",
      uploadConfirmCancel: "Annuleren",
      unsupportedUploadTitle: "Upload wordt niet ondersteund",
      unsupportedUploadMsg:
        "Het spijt ons maar deze browser ondersteunt het uploaden van audio files niet.",
      unsupportedUploadBtn: "Begrepen",
      deleteMsg: "Weet je zeker dat je '{{ name }}' wilt verwijderen??",
      deleteOk: "Bevestig",
      deleteCancel: "Annuleer",
      noCreditTitle: "Plan opwaarderen",
      noCreditMsg:
        "Je hebt onvoldoende krediet. Upgrade naar een plan om verder te gaan.",
    },
    REGISTER: {
      title: "Registeer hier voor een GRATIS proefplan",
      subtitle: "Krijg 30 minuten audio vertaling GRATIS",
      alreadyQuestion: "Heb je een account?",
      alreadyAction: "Inloggen",
      btn: "Registeren",
      forgotPassword: "Wachtwoord vergeten?",
      createPwd: "Kies een wachtwoord",
      confirmPwd: "Bevestig wachtwoord",
      loggedinActionBtn: "Ga naar mijn bestanden",
    },
    SHORTCUTS: {
      playPause: "Afspelen en pauzeren van je audio bestand",
      rewind: "Ga vijf seconden terug in je audio bestand",
      forward: "Spoel je audio vijf seconden vooruit",
      skip: "Sla een woord over",
      reverse: "Ga een woord terug",
      highlight: "Tekst markeren",
      undo: "Ongedaan maken",
      redo: "Herstellen",
      altClick: "Audio laten verspringen bij klikken",
    },
    STATUS: {
      actionBtn: "Upgrade naar een abonnement",
      remainingMsg: "Transcriptie tegoed: {{ balance }}",
    },
    UPLOADS: {
      id: "ID",
      owner: "Eigenaar",
      length: "Lengte",
      date: "Datum",
      noId: "Geen ID gespecificeerd",
      loading: "Bezig met het laden van je uploads...",
      noResults: "Nog geen uploads",
    },
    TOUR: {
      back: "Vorige",
      close: "Sluit",
      last: "Bedankt!",
      next: "Volgende",
      skip: "Sla over",
      dismissBtn: "Laad dit niet meer zien",
      EDITOR: {
        beginTitle: "Welkom",
        begin:
          "In dit scherm laten we je kort zien hoe je Amberscript optimaal kunt gebruiken",
        altClick:
          "Je kunt audio vooruit spoelen door de alt toets ingedrukt te houden als je op een woord klikt",
        play: "Hiermee kun je jouw audio bestand afspelen en stoppen. Je kunt hiervoor de shortcut combinatie control + space gebruiken",
        rewind:
          "Als je op deze knop drukt ga je automatisch 5 seconden terug. Je kunt deze knop bedienen met de shortcut combinatie control + r",
        highlight:
          "Hiermee kun je gemakkelijk highlights aanbrengen op je audiotijdslijn. Als je een stuk tekst selecteert zul je zien dat er een highlight in je tijdslijn terugkomt",
        shortcuts:
          "Hiermee krijg je alle handige shortcuts te zien die je kunt gebruiken om Amberscript sneller te gebruiken",
        startTime:
          "Door hier te klikken kan je de starttijd van de tijdscodes van elke alinea aanpassen om deze te synchroniseren met de originele bron, zoals bijvoorbeeld een video",
        end: "Dit was het! Meer vragen? Neem contact op met ons via <a href='mailto:info@amberscript.nl'>info@amberscript.nl</a>",
      },
    },
    REFERRAL: {
      title: "Help jouw vrienden tijd te besparen",
      description:
        "Nodig jouw vrienden uit Amberscript te gebruiken - voor elke persoon die zich aanmeldt ontvang je tot {signupCreditMinutes} minuten gratis transcriptie.",
      terms: "Voorwaarden van toepassing",
      enterEmail: "Vul e-mail adres in",
      sendButton: "Verzend uitnodigingen",
      invitationSent:
        "Jouw uitnodiging is verstuurd. Graag kun je nog meer vrienden uitnodigen!",
      shareInviteLink: "Deel jouw uitnodigingslink",
      transcriptionCredit: "Jouw transcriptie-tegoed",
      pending: "Pending",
      earned: "Verdiende credits",
      copy: "Kopiëren",
      copied: "Gekopieerd!",
      errorTitle: "De referral code is verlopen",
      errorDescription:
        "We informeren je over nieuwe campagnes via e-mail of sociale media.",
    },
    REALIGN: {
      btnName: "Tijdsmarkeringen aanpassen",
      dialogTitle: "Bezig met oplijnen",
      dialogContent:
        "De tijdsmarkeringen worden verbeterd in het transcript, even geduld alstublieft...",
    },
    TRANSACTIONS: {
      id: "ID",
      owner: "Eigenaar",
      seconds: "Seconden",
      date: "Datum",
      noId: "Geen ID gespecificeerd",
      loading: "Bezig met het laden van je transacties ...",
      noResults: "Geen transacties",
    },
    THANKS: {
      mainMsg: "Bedankt!",
      summaryTitle: "Samenvatting van je order",
    },
    SCRAMBLED_DIALOG: {
      title: "Oeps! Er is een fout opgetreden in je bestand",
      description1: "De tekst in je bestand is ongewild door elkaar gehaald.",
      description2:
        "Hierdoor moet je bestand worden hersteld naar een vorige staat, je laatste aanpassing zal verloren gaan omdat de pagina ververst moet worden.",
      titleFeedback:
        "Laat ons alsjeblieft weten welke acties je ondernam voordat je deze fout in beeld kreeg zodat we de oorzaak van het probleem kunnen vinden.",
      descriptionButton: "De pagina zal automatisch ververst worden",
    },
    COUNTRIES: {
      AFG: "Afghanistan",
      ALA: "Åland Islands",
      ALB: "Albania",
      DZA: "Algeria",
      ASM: "American Samoa",
      AND: "Andorra",
      AGO: "Angola",
      AIA: "Anguilla",
      ATA: "Antarctica",
      ATG: "Antigua and Barbuda",
      ARG: "Argentina",
      ARM: "Armenia",
      ABW: "Aruba",
      AUS: "Australia",
      AUT: "Austria",
      AZE: "Azerbaijan",
      BHS: "Bahamas",
      BHR: "Bahrain",
      BGD: "Bangladesh",
      BRB: "Barbados",
      BLR: "Belarus",
      BEL: "Belgium",
      BLZ: "Belize",
      BEN: "Benin",
      BMU: "Bermuda",
      BTN: "Bhutan",
      BOL: "Bolivia, Plurinational State of",
      BES: "Bonaire, Sint Eustatius and Saba",
      BIH: "Bosnia and Herzegovina",
      BWA: "Botswana",
      BVT: "Bouvet Island",
      BRA: "Brazil",
      IOT: "British Indian Ocean Territory",
      BRN: "Brunei Darussalam",
      BGR: "Bulgaria",
      BFA: "Burkina Faso",
      BDI: "Burundi",
      KHM: "Cambodia",
      CMR: "Cameroon",
      CAN: "Canada",
      CPV: "Cape Verde",
      CYM: "Cayman Islands",
      CAF: "Central African Republic",
      TCD: "Chad",
      CHL: "Chile",
      CHN: "China",
      CXR: "Christmas Island",
      CCK: "Cocos (Keeling) Islands",
      COL: "Colombia",
      COM: "Comoros",
      COG: "Congo",
      COD: "Congo, the Democratic Republic of the",
      COK: "Cook Islands",
      CRI: "Costa Rica",
      CIV: "Côte d'Ivoire",
      HRV: "Croatia",
      CUB: "Cuba",
      CUW: "Curaçao",
      CYP: "Cyprus",
      CZE: "Czech Republic",
      DNK: "Denmark",
      DJI: "Djibouti",
      DMA: "Dominica",
      DOM: "Dominican Republic",
      ECU: "Ecuador",
      EGY: "Egypt",
      SLV: "El Salvador",
      GNQ: "Equatorial Guinea",
      ERI: "Eritrea",
      EST: "Estonia",
      ETH: "Ethiopia",
      FLK: "Falkland Islands (Malvinas)",
      FRO: "Faroe Islands",
      FJI: "Fiji",
      FIN: "Finland",
      FRA: "France",
      GUF: "French Guiana",
      PYF: "French Polynesia",
      ATF: "French Southern Territories",
      GAB: "Gabon",
      GMB: "Gambia",
      GEO: "Georgia",
      DEU: "Germany",
      GHA: "Ghana",
      GIB: "Gibraltar",
      GRC: "Greece",
      GRL: "Greenland",
      GRD: "Grenada",
      GLP: "Guadeloupe",
      GUM: "Guam",
      GTM: "Guatemala",
      GGY: "Guernsey",
      GIN: "Guinea",
      GNB: "Guinea-Bissau",
      GUY: "Guyana",
      HTI: "Haiti",
      HMD: "Heard Island and McDonald Islands",
      VAT: "Holy See (Vatican City State)",
      HND: "Honduras",
      HKG: "Hong Kong",
      HUN: "Hungary",
      ISL: "Iceland",
      IND: "India",
      IDN: "Indonesia",
      IRN: "Iran, Islamic Republic of",
      IRQ: "Iraq",
      IRL: "Ireland",
      IMN: "Isle of Man",
      ISR: "Israel",
      ITA: "Italy",
      JAM: "Jamaica",
      JPN: "Japan",
      JEY: "Jersey",
      JOR: "Jordan",
      KAZ: "Kazakhstan",
      KEN: "Kenya",
      KIR: "Kiribati",
      PRK: "Korea, Democratic People's Republic of",
      KOR: "Korea, Republic of",
      KWT: "Kuwait",
      KGZ: "Kyrgyzstan",
      LAO: "Lao People's Democratic Republic",
      LVA: "Latvia",
      LBN: "Lebanon",
      LSO: "Lesotho",
      LBR: "Liberia",
      LBY: "Libya",
      LIE: "Liechtenstein",
      LTU: "Lithuania",
      LUX: "Luxembourg",
      MAC: "Macao",
      MKD: "Macedonia, the former Yugoslav Republic of",
      MDG: "Madagascar",
      MWI: "Malawi",
      MYS: "Malaysia",
      MDV: "Maldives",
      MLI: "Mali",
      MLT: "Malta",
      MHL: "Marshall Islands",
      MTQ: "Martinique",
      MRT: "Mauritania",
      MUS: "Mauritius",
      MYT: "Mayotte",
      MEX: "Mexico",
      FSM: "Micronesia, Federated States of",
      MDA: "Moldova, Republic of",
      MCO: "Monaco",
      MNG: "Mongolia",
      MNE: "Montenegro",
      MSR: "Montserrat",
      MAR: "Morocco",
      MOZ: "Mozambique",
      MMR: "Myanmar",
      NAM: "Namibia",
      NRU: "Nauru",
      NPL: "Nepal",
      NLD: "Netherlands",
      NCL: "New Caledonia",
      NZL: "New Zealand",
      NIC: "Nicaragua",
      NER: "Niger",
      NGA: "Nigeria",
      NIU: "Niue",
      NFK: "Norfolk Island",
      MNP: "Northern Mariana Islands",
      NOR: "Norway",
      OMN: "Oman",
      PAK: "Pakistan",
      PLW: "Palau",
      PSE: "Palestinian Territory, Occupied",
      PAN: "Panama",
      PNG: "Papua New Guinea",
      PRY: "Paraguay",
      PER: "Peru",
      PHL: "Philippines",
      PCN: "Pitcairn",
      POL: "Poland",
      PRT: "Portugal",
      PRI: "Puerto Rico",
      QAT: "Qatar",
      REU: "Réunion",
      ROU: "Romania",
      RUS: "Russian Federation",
      RWA: "Rwanda",
      BLM: "Saint Barthélemy",
      SHN: "Saint Helena, Ascension and Tristan da Cunha",
      KNA: "Saint Kitts and Nevis",
      LCA: "Saint Lucia",
      MAF: "Saint Martin (French part)",
      SPM: "Saint Pierre and Miquelon",
      VCT: "Saint Vincent and the Grenadines",
      WSM: "Samoa",
      SMR: "San Marino",
      STP: "Sao Tome and Principe",
      SAU: "Saudi Arabia",
      SEN: "Senegal",
      SRB: "Serbia",
      SYC: "Seychelles",
      SLE: "Sierra Leone",
      SGP: "Singapore",
      SXM: "Sint Maarten (Dutch part)",
      SVK: "Slovakia",
      SVN: "Slovenia",
      SLB: "Solomon Islands",
      SOM: "Somalia",
      ZAF: "South Africa",
      SGS: "South Georgia and the South Sandwich Islands",
      SSD: "South Sudan",
      ESP: "Spain",
      LKA: "Sri Lanka",
      SDN: "Sudan",
      SUR: "Suriname",
      SJM: "Svalbard and Jan Mayen",
      SWZ: "Swaziland",
      SWE: "Sweden",
      CHE: "Switzerland",
      SYR: "Syrian Arab Republic",
      TWN: "Taiwan, Province of China",
      TJK: "Tajikistan",
      TZA: "Tanzania, United Republic of",
      THA: "Thailand",
      TLS: "Timor-Leste",
      TGO: "Togo",
      TKL: "Tokelau",
      TON: "Tonga",
      TTO: "Trinidad and Tobago",
      TUN: "Tunisia",
      TUR: "Turkey",
      TKM: "Turkmenistan",
      TCA: "Turks and Caicos Islands",
      TUV: "Tuvalu",
      UGA: "Uganda",
      UKR: "Ukraine",
      ARE: "United Arab Emirates",
      GBR: "United Kingdom",
      USA: "United States",
      UMI: "United States Minor Outlying Islands",
      URY: "Uruguay",
      UZB: "Uzbekistan",
      VUT: "Vanuatu",
      VEN: "Venezuela, Bolivarian Republic of",
      VNM: "Viet Nam",
      VGB: "Virgin Islands, British",
      VIR: "Virgin Islands, U.S.",
      WLF: "Wallis and Futuna",
      ESH: "Western Sahara",
      YEM: "Yemen",
      ZMB: "Zambia",
      ZWE: "Zimbabwe",
    },
    GLOSSARY: {
      title: "Woordenboek",
      names: "Namen",
      terms: "Termen",
      notFound:
        "Het woordenboek kon niet worden gevonden. Maak de volgende keer een woordenboek voordat je je bestand uploadt om de nauwkeurigheid van je tekst te verbeteren.",
    },
    MACHINE_TRANSLATIONS: {
      direct: "Automatisch",
      perfect: "Professioneel",
    },
    EDITOR_LANGUAGE: {
      en: "Engels",
      "en-us": "Engels (Verenigde Staten)",
      "en-uk": "Engels (Verenigd Koninkrijk)",
      nl: "Nederlands",
      de: "Duits",
      fr: "Frans",
      es: "Spaans",
      it: "Italiaans",
      pt: "Portugees",
      da: "Deens",
      no: "Noors (Bokmål)",
      sv: "Zweeds",
      pl: "Pools",
      ro: "Roemeens",
      uk: "Oekraïens",
      ru: "Russisch",
      bg: "Bulgaars",
      cs: "Tsjechisch",
      el: "Grieks",
      et: "Ests",
      hu: "Hongaars",
      ja: "Japans",
      ko: "Koreaans",
      lt: "Litouws",
      lv: "Lets",
      sk: "Slowaaks",
      sl: "Sloveens",
      tr: "Turks",
      zh: "Chinees",
    },
  },
};
