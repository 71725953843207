export const it = {
  locale: "it",
  messages: {
    MISC: {
      formErrRequired: "Immettere almeno un valore",
      formErrEmail: "Indirizzo email invalido",
      formHintYes: "Si",
      formHintQuestion: "Usa {{ what }}?",
      signedInAs: "Hai effettuato accesso come",
      logout: "Esci",
      youOwner: "Il tuo account",
      closeDialogBtn: "Chiudi",
      ariaCloseDialog: "Chiudi finestra",
      clearSearch: "Chiudi ricerca",
      serverErrorDefaultToastMsg: "Errore server",
      ienoticeTitle: "Motore di ricerca non supportato",
      ienoticeTxt:
        "Internet Explorer al momento non è supportato. Si prega di usare un altro motore di ricerca, come Google Chrome o Microsoft Edge",
      ienoticeDismiss: "OK",
      requestFullTranscriptBodyDialog:
        "Se desideri che la trascrizione sia perfezionata dai nostri esperti trascrittori, carica il file nuovamente e scegli il tipo di trascrizione 'Perfect'.",
      requestFullTranscriptTitle: "Ottieni testo preciso al 100%",
      requestFullTranscriptBody:
        "Richiedi un preventivo per una trascrizione revisionata dai nostri trascrittori umani",
    },
    CONFIRM_SUBMIT: {
      title: "Sei sicuro di voler inviare il file?",
      checkbox1:
        "Ho controllato le note aggiuntive e implementato i requisiti specifici del cliente, se richiesto.",
      checkbox2:
        "L'intero output ASR del file è stato verificato con il correttore ortografico e grammaticale.",
      checkbox3: "La trascrizione è stata creata secondo le linee guida.",
      back: "Riportami al file",
      submit: "Invia il file per la revisione",
    },
    TRANSCRIPT_ERROR: {
      message:
        "Non hai più accesso a questo file. Questo file potrebbe essere stato riassegnato. Se hai delle domande, contatta Operations.",
      button: "Ritorna alla Dashboard",
    },
    NAV: {
      titleAccount: "Account",
      titleAccountNew: "Aggiungi nuovo utente",
      titleAccountMy: "Il mio account",
      titleBilling: "Fatturazione",
      titleInvoices: "Fatture",
      titleCheckout: "Checkout",
      titleCoupon: "Utilizza coupon",
      titleInvite: "Invita amici",
      titleLogin: "Entra",
      titlePasswordReset: "Resetta password",
      titleEmailvalidate: "Conferma email",
      titlePlan: "Abbonamento",
      titleCosts: "Costi",
      titleRecords: "I miei file",
      titleRecordsTooltip: "Pannello di controllo",
      titleRegister: "Registrati",
      titleTransactions: "Transazioni",
      titleUploads: "Caricamenti",
      titleDashboard: "Pannello di controllo",
      titleManageUsers: "Gestisci utenti",
      titleAddUser: "Aggiungi utente",
      titleManageTranscriptions: "Gestisci trascrizioni",
      tooltipAdmin: "Amministratore",
      titleAdminUser: "Gestisci utente",
      tooltipLanguage: "Cambia lingua",
      newBadge: "nuovo",
      help: "Aiuto",
      currentPlanFallback: "Passa all'abbonamento mensile",
      currentPlanTooltip: "Abbonamento attuale",
    },
    LANGUAGES: {
      en: "Inglese",
      nl: "Olandese",
      de: "Tedesco",
      fr: "Francese",
      es: "Spagnolo",
      it: "Italiano",
      pt: "Portoghese",
    },
    ACCOUNT: {
      titleForm: "Dettagli Account",
      username: "Username",
      email: "Email",
      firstName: "Nome",
      lastName: "Cognome",
      company: "Azienda",
      pwd: "Password",
      newPwd: "Nuova Password",
      confirmPwd: "Conferma Password",
      changePwd: "Cambia password",
      createPwd: "Crea password",
      msgUsernameMaxlength:
        "L'username non deve essere più lungo di {{ quantity }} caratteri.",
      msgPwdLength: "La password deve essere almeno {{ quantity }} caratteri.",
      msgPwdPattern:
        "La password deve contenere almeno un numero, una maiuscola, una minuscola e un simbolo.",
      msgPwdConfirm: "Conferma la nuova password",
      msgPwdMismatch: "Le due password non coincidono",
      loading: "Caricando i dati...",
      update: "Aggiorna dati",
      updating: "Salvando i dati...",
      save: "Salva utente",
      saving: "Salvando utente...",
    },
    ADMIN: {
      add: {
        donateSeconds: "Dona secondi",
      },
      dashboard: {
        loading: "Caricando pannello di controllo...",
        noResults: "Nessun elemento disponibile",
      },
      transactions: {
        id: "ID",
        owner: "Proprietario",
        seconds: "Secondi",
        date: "Data",
        noId: "Nessun ID specificato",
        loading: "Caricando transazioni...",
        noResults: "Nessuna transazione da mostrare",
        results: "Transazioni:",
      },
      transcriptions: {
        created: "Creato",
        id: "ID",
        name: "Nome",
        user: "Utente",
        status: "Status",
        length: "Durata",
        type: "Tipo",
        loading: "Caricando trascrizioni...",
        noResults: "Nessuna trascrizione da mostrare",
        results: "Trascrizioni:",
      },
      users: {
        created: "Creato il",
        username: "Username",
        fullname: "Nome completo",
        status: "status",
        minutes: "Durata audio",
        pause: "Metti utente in pausa",
        activate: "Attiva utente",
        delete: "Elimina utente",
        subscribed: "Abbonato",
        loading: "Caricando utenti...",
        noResults: "Nessun utente da mostrare",
        results: "Utenti:",
        deleteConfirm: "Sei sicuro di volere eliminare '{{ name }}'?",
        deleteOk: "Si",
        deleteCancel: "Cancella",
        search: "Cerca utenti",
      },
      user: {
        navBackToUsers: "Tutti gli Utenti",
        navEdit: "Profilo",
        navTranscriptions: "Trascrizioni",
        navTransactions: "Transazioni",
        navDonate: "Dona",
        fullName: "Nome completo",
        availableSeconds: "Tempo disponibile",
        created: "Creato il",
        isSubscribed: "Abbonamento attuale",
        isntSubscribed: "Nessun abbonamento",
        status: "Status",
        userType: "Tipo",
        language: "Lingua preferita",
        makeAdmin: "Abilità amministratore",
        cancelSubscription: "Cancella abbonamento",
        cancellingSubscription: "Cancellando...",
      },
      donate: {
        title: "Dona ore",
        username: "Username destinatario",
        seconds: "Numero di secondi",
        secondsFormatted: "Ore : Minuti : Secondi",
        comment: "Messaggio",
        submit: "Invia",
        submitting: "Inviando...",
      },
      sendvalidationemail: {
        submit: "Invia conferma per email",
        submitting: "Inviando...",
      },
    },
    BILLING: {
      titleForm: "Dettagli Fatturazione",
      firstName: "Nome",
      lastName: "Cognome",
      email: "Email",
      phone: "Telefono",
      company: "Azienda",
      vatNumber: "Partita IVA",
      address: "Indirizzo",
      address2: "Indirizzo 2",
      city: "Città",
      country: "Paese",
      zipCode: "CAP",
      state: "Regione",
      loading: "Caricando dettagli...",
      save: "Salva dettagli",
      saving: "Salvando dettagli...",
    },
    CHECKOUT: {
      choose: "1. Scegli abbonamento",
      setup: "2. Dettagli pagamento",
      confirm: "3. Conferma",
      loadingStripe: "Caricando Stripe...",
      titleCard: "Dettagli carta",
      titleBilling: "Dettagli Fatturazione",
      titleSummary: "Riepilogo ordine",
      subscribe: "Abbonati",
      subscribing: "Abbonandoti...",
      info: "Amberscript accetta le seguenti carte di credito e debito. I pagamenti sono gestiti in sicurezza tramite Stripe.",
      labelNameOnCard: "Nome sulla carta",
      labelCard: "Numero carta",
      termsDisclaimer:
        "Continuando accetti i Termini e le Condizioni d'Uso di Amberscript",
      termsDisclaimerLinkTxt: "Termini e Condizioni d'Uso",
    },
    SUMMARY: {
      plan: "Abbonamento:",
      cost: "Costo:",
      beyond: "Oltre",
      hrs: "ore",
      hour: "ora",
      billingCycle: "Ciclo di fatturazione:",
      billingInterval: "mensile",
      total: "Totale:",
      loading: "Caricando riepilogo...",
      error: "Caricamento riepilogo fallito :(",
    },
    COSTS: {
      titlePlan: "Abbonamento attuale",
      titleCosts: "Riepilogo",
      titleInvoices: "Fatture",
      noResultsPlan: "Risparmia e passa al piano Mensile",
      noResultsCosts: "Non hai costi per questo mese",
      noResultsInvoices: "Nessuna fattura.",
      loadingSummary: "Caricando riepilogo...",
      loadingInvoices: "Caricando fatture...",
      summary:
        "Questo mese hai usato {{ time }} per un costo totale di {{ cost }} euro.",
      remainder:
        "Con il tuo abbonamento {{ planName }} paghi {{ price }} all'ora",
      remainderB: "hai {{ num }} ore gratis incluse",
      remainderC1: "ed un costo fisso di {{ cost }} per {{ interval }}.",
      remainderC2: "e nessun costo fisso.",
      INVOICES: {
        invoiceNr: "Nr",
        created: "Creato",
        description: "Descrizione",
        monthPeriod: "Mese",
        yearPeriod: "Anno",
        nettoAmount: "Netto",
        vatAmount: "IVA",
      },
    },
    COUPON: {
      title: "Riscatta un coupon",
      inputLabel: "Codice coupon",
      submit: "Invia",
      submitting: "Inviando...",
      cancel: "Cancella",
      invalidCouponCode: "Codice coupon invalido",
    },
    EDITOR: {
      tooManyRows: "Troppe righe",
      busy: "Caricamento ...",
      feedbackBtn: "Dicci la tua opinione!",
      shortcutsBtn: "Mostra tutte le scorciatoie",
      shortcutsHint: "Vuoi usare Play o Pausa? Usa Control + Spazio",
      shortcutHintAltClick:
        "Vuoi che l'audio vada avanti quando clicchi sul testo? Tieni premuto alt",
      shortcutHintNewLine:
        "Vuoi aggiungere una nuova linea all'interno di un segmento? Premi shift + enter",
      shortcutsTitle: "Tasti scelta rapida",
      ariaShortcutsClose: "Chiudi tasti scelta rapida",
      replayTour: "Riproduci di nuovo il Tour",
      errorEmptySegments:
        "Amber non ha riconosciuto nessuna parola nella lingua che hai selezionato. Assicurati che l'audio contenga discorsi parolati e che i tuoi crediti siano sufficienti. Contattaci per ogni dubbio!",
      errorLoadAudio: "Errore nel caricamento file",
      errorLoadXML: "Errore nel caricamento file",
      errorReadAudio: "Error reading the audio file",
      loadingAudio: "Caricando audio...",
      loading: "Caricando trascrizione...",
      parsing: "Analizzando i dati della trascrizione...",
      speakerClear: "Elimina interlocutore",
      speakerAddBtn: "Aggiungi nuovo",
      speakerAddTitle: "Aggiungi interlocutore",
      speakerEditTitle: "Modifica interlocutore",
      findreplTitle: "Trova e Sostituisci",
      findreplFindLabel: "Trova:",
      findreplReplLabel: "Sostituisci con:",
      findreplReplBtn: "Sostituisci tutti",
      infoTitle: "Informazioni Trascrizione",
      infoClose: "Chiudi Informazioni Trascrizione",
      infoNameLabel: "Nome",
      infoDateLabel: "Data",
      infoCancel: "Cancella",
      infoSave: "Salva",
      infoSaving: "Salvando...",
      feedbackTitle: "Commenti",
      feedbackRatingLabel: "Quanto era buona la qualità audio?",
      feedbackCommentLabel: "Lasciua un commento",
      feedbackSubmit: "Invia",
      feedbackSubmitting: "Inviando...",
      feedbackVal1: "Pessima",
      feedbackVal2: "Scarsa",
      feedbackVal3: "OK",
      feedbackVal4: "Buona",
      feedbackVal5: "Eccellente",
      exportBtn: "Esporta",
      exportXML: "XML",
      exportWord: "Word",
      autosaveSuccess: "Tutti i cambiamenti salvati",
      autosavePending: "Salvando...",
      autoSaveSaved: "Salvato",
      autoSaveError: "Errore nel salvataggio",
      autoSaveTryAgain: "Clicca qui per riprovare",
      metaCreated: "Creato",
      metaLength: "Durata",
      metaStartTimeOffset: "Tempo d'inizio",
      metaStartTimeOffsetLabel: "Tempo inizio (hh:mm:ss:ms)",
      metaStartTimeOffsetTitle: "Allinea segnatempo di inizio",
      metaStartTimeOffsetDescription:
        "Allinea segnatempo di inizio, per sincronizzare con la fonte originale. Inserisci un tempo, sarà aggiunto a tutti i segnatempo originali della trascrizione",
      metaStartTimeOffsetSubmit: "Allinea segnatempo",
      metaWords: "Parole",
      metaPlaybackRate: "Velocità",
      metaPlaybackRateTip: "Cambia la velocità di riproduzione",
      controlsTranslation: "Aggiungi le traduzioni",
      addNewLanguage: "Aggiungi una nuova lingua",
      controlsSpellCheck: "Toggle per controllo grammaticale",
      controlsSpellCheckShortcut: "(ctrl+g)",
      controlsReplace: "Sostituisci",
      controlsReplaceShortcut: "(ctrl+f)",
      controlsUndo: "Annulla",
      controlsUndoShortcut: "(ctrl+z)",
      controlsRedo: "Ripeti",
      controlsRedoShortcut: "(ctrl+shift+z)",
      controlsHighlight: "Evidenzia",
      controlsHighlightShortcut: "(ctrl+h)",
      controlsSubtitles: "Sottotitoli",
      controlsSubtitlesShortcut: "Trasforma in Sottotitoli",
      rewindBtn: "Riavvolgi",
      resumeBtn: "Riprendi",
      ariaPlay: "Play",
      ariaPause: "Pausa",
      blockTextLength: "{textLength} caratteri",
      adjustStartTimestampHeader: "Regola segnatempo di inizio",
      adjustEndTimestampHeader: "Regola segnatempo di fine",
      adjustDialogSubmit: "Regola",
      adjustDialogCancel: "Cancella",
      adjustTimestampDescription:
        "I segnatempo minimi e massimi sono determinati dai segnatempo delle parole precedenti e successive.",
      subtitlesDialogHeader: "Trasforma in Sottotitoli",
      subtitlesDialogDescription:
        "Converti automaticamente la tua trscizione in frasi pronte per i sottotitoli. Amberscript dividerà il testo in frasi più corte della dimensione giusta per adattarsi allo schermo.",
      subtitlesDialogNote:
        "Nota: Questa azione non può essere annullata automaticamente. Il tuo testo sarà comunque modificabile.",
      subtitlesDialogAdvancedOptions: "Opzioni avanzate",
      subtitlesDialogSubmit: "Converti",
      subtitlesDialogSubmitLoading: "Un momento...",
      subtitlesDialogCancel: "Cancella",
      noEditingForMobileOrTablet:
        "Al momento Amberscript non supporta le modifiche sul tuo dispositivo. Amberscript lavora al meglio su portatile o computer fisso, usando Google Chrome. Supporta anche Microsoft Edge, Apple Safari e Mozilla Firefox.",
      emailValidationTitle: "Email non ancora confermata",
      emailValidationDescription:
        "Per esportare o copiare la tua trascrizione, conferma la tua email.",
      btnAlreadyActivated: "Ho già confermato la mia email",
      btnSendActivationAgain: "Invia di nuovo email di conferma",
      btnActivationSent: "Email di conferma inviata!",
      alertLoggedOutTitle: "E' necessario effettuare l'accesso",
      alertLoggedOutDescription:
        "Sei già uscito su un'altra finestra. Per favore, entra di nuovo per usare Amberscript.",
      alertLoggedOutButton: "Vai ad Entra",
      alertAccessDeniedTitle: "Accesso negato",
      alertAccessDeniedDesciption: "Non hai accesso a questo file.",
      btnApproveOrReject: "Approvalo o rifiutalo...",
      btnDetails: "Dettagli",
      dialogJobFeedback: {
        btnApprove: "Approvare",
        btnReject: "Rifiutare",
        title: "Approvare o rifiutare il controllo di qualità",
        rateTranscriber:
          "Vota {email} in ogni area per questo lavoro, 1 è il più basso, 5 è la massima qualità. Ho bisogno di aiuto? Leggi le linee guida di controllo della qualità.",
        textAreaPlaceholder: "Aggiungi note...",
        notesExplanation:
          "Richiesto: aggiungi note, anche quando si approva, quindi il trascrittore può vedere il tuo feedback.",
        transcription: "Tekst",
        syntax: "Richtlijnen",
        grammar: "Spelling/Tikfouten/Grammatica",
        punctuation: "Interpunctie/Syntax",
        research: "Ricerca (nomi e gergo)",
        difficulty: "Livello di difficoltà",
        difficultyExplanation:
          "Questo punteggio non fa parte del calcolo del punteggio totale.",
        total: "Valutazione totale della trascrizione",
        tooltip: {
          transcription:
            "It is checked whether the spoken text has been correctly converted into written text. This includes the accurate reproduction of words, sentences and statements without errors or omissions, in accordance with the guidelines. It is checked whether there were any listening errors that could have led to inaccurate or incorrect interpretation of the transcriptions. (30%)",
          syntax:
            "This is where it is checked whether the transcription was carried out in accordance with the established guidelines.This includes the use of time marks for unintelligible passages, the labelling of speakers, the observance of pauses and sentence breaks as well as other guidelines that apply to the transcription. All special guidelines are evaluated especially strictly. It must be checked whether the formatting of the transcript is appropriate. This may include line spacing, font size, arrangement of text elements and other aspects of the visual presentation of the transcript. (25%)",
          grammar:
            "In this category, grammatical errors as well as spelling and typing errors in the transcript are evaluated. The aim is to ensure that the text is both grammatically correct and free of spelling errors according to guidelines. This includes checking for errors such as misspelled words, missing capitalisation and errors in the use of abbreviations. (25%)",
          punctuation:
            "It is checked whether punctuation marks such as full stops and commas have been used correctly to improve the readability and comprehensibility of the text. It also assesses whether the use of quotes is appropriate and whether other punctuation marks have been used correctly. Another aspect of this category is the correct division of long sentences. (15%)",
          research:
            "This category focuses on evaluating the research of technical terms, proper names and other specialised expressions that appear in the transcript. It is checked whether these terms have been spelt and used correctly and, if available, whether terms from a glossary have been used. (5%)",
        },
      },
      SUBTITLE: {
        sliderShorterLabel: "Più corto",
        sliderLongerLabel: "Più lungo",
        sliderRecommendedLabel: "Consigliato",
        slidercharsPerSecLabel: "{charsPerSec} caratteri / sec.",
      },
      EXPORT: {
        title: "Esporta testo",
        format: "Formato",
        export: "Esporta",
        optionsTitle: "Esporta opzioni",
        optionsTimestamps: "Includi segnatempo",
        optionsSpeakers: "Includi interlocutori",
        optionsIncludeHighlights: "Includi parti evidenziate",
        optionsHighlights: "Esporta solo parti evidenziate",
        maxCharsPerRow: "Numero massimo caratteri raggiunto",
        milliseconds: "Millisecondi",
        fps: "FPS",
        cancel: "Cancella",
        ok: "Esporta",
        transcribedByAmberScript:
          "Questa trascrizione è stata creata da www.amberscript.com",
        wayOfExport: "Come vuoi esportare il tuo file?",
        text: "Testo",
        textDesc:
          "Documento Word o TXT. Ottimi per la trascrizione di interviste, meeting e media tag. ",
        subtitleDesc:
          "SRT, VTT o EBU-STL. Possono essere usati per i Social Media, YouTube, Video players e molto altro.",
        video: "Video",
        videoDesc:
          "Scarica video con o senza i sottotitoli inclusi. Ottimo per condividere video con i sottotitoli incorporati sui Social Media.",
        rules: "Regole",
        burnSubToVideo: "Incorpora i sottotitoli al video - Prossimamente",
        subtitleRule: "Max. {maxLine} righe, {maxChar} caratteri per riga",
        realignRecommendation:
          "Suggeriamo di riallineare i segnatempo prima di esportare.",
        realignRecommendationDesc:
          "Modificare la tua trascrizione significa che l'audio deve essere riallineato per coincidere con le tue modifiche.",
        realignRecommendation1: "Presto",
        realignRecommendation1Desc:
          "Riallineamento veloce e generale. Potrebbe non essere accurato al 100%.",
        realignRecommendation2: "Completo - Prossimamente",
        realignRecommendation2Desc:
          "Riallineamento accurato e preciso del tuo audio. Potrebbe richiedere alcuni minuti.",
        realignRecommendation3: "Esporta senza riallineare",
        realignRecommendation3Desc:
          "I tuoi segnatempo potrebbero non essere precisi.",
        emailNotification:
          "Riceverai un'email quando il tuo file sarà pronto per essere scaricato.",
        options: "Opzioni",
        whatIsJson:
          "JSON è un formato dati che può essere utilizzato per le applicazioni.",
        audio: "Audio",
        audioDesc: "Scarica il tuo media file originale",
        downloadAudio: "Scarica Audio",
        downloadVideo: "Scarica Video",
        exportSubtitleTitle: "Esporta Sottotitoli",
        custom: "Personalizza",
        maxNrRows: "Numero massimo di righe",
        maxCharPerRow:
          "Numero massimo di caratteri per riga, inclusi gli spazi",
        titleTranscript: "Esporta file",
        downloadFile: "Scarica file audio",
        asAFile: "Come file",
        asAFileDesc:
          "SRT, VTT o EBU-STL. Può essere usato per Social Media, Youtube, Lettori Video ed altro.",
        download: "Scarica",
        asAVideoWithBurnIn: "Come video con sottotitoli incorporati",
        asAVideoWithBurnInDesc:
          "I sottotitoli appariranno permanentemente sul file video. Esporta come mp4, etc.",
        createNewVersion: "Crea una nuova versione",
        lastExport: "Ultimo download:",
        additionalOptions: "Altre opzioni",
        downloadAsText: "Scarica come testo",
        downloadAudioFile: "Scarica file audio",
        downloadVideoFile: "Scarica file video",
        postVideoOnYoutube: "Pubblicare il video su YouTube",
        intelligentExport: "Esportazione intelligente",
        summary: "Riassunto",
        summaryTooltip: "Generare un riassunto della trascrizione",
        anonymization: "Anonimizzazione",
        anonymizationTooltip:
          "Anonimizza tutti i dati personali (nomi, indirizzi, ecc.) della trascrizione.",
        cleanRead: "Lettura pulita",
        cleanReadTooltip:
          "Genera una trascrizione modificata per facilitarne la lettura.",
        hint: "Si noti che per questa funzionalità i dati lasciano la piattaforma Amberscript e l'UE.",
      },
      BURN_IN: {
        processingVideo: "Video in elaborazione",
        confirmationTitle: "Ti soddisfa il tuo file?",
        confirmationText:
          "Puoi incorporare questa versione dei sottotitoli soltanto una volta. Se modifichi il file di sottotitoli dopo questa operazione e desideri incorporarli di nuovo al video, ti verrà addebitata una nuova tariffa.",
        continue: "Si, procedi",
        back: "No, continua a modificare",
        successTitle: "Ordine riuscito",
        successText1:
          "Si prega di attendere il tempo richiesto per elaborare il file. Quando il file sarà completo, riceverai notifica tramite email e potrai scaricare il video sulla dashboard o nell’editor cliccando su",
        successText2: ".",
        export: "Esporta",
        returnToEditor: "Ritorna all' editor",
        continueToDashboard: "Vai alla dashboard",
        notAvailableText: "Vuoi esportare di nuovo?",
        notAvailableLink: "Contatta il servizio clienti",
      },
      toast: {
        onCopy: "Funzioni di copia, incolla e taglia non disponibili.",
        onCut: "Funzioni di copia, incolla e taglia non disponibili.",
        onPaste: "Funzioni di copia, incolla e taglia non disponibili.",
      },
      RPT: {
        title: "Déjanos hacer la edición por ti",
        description:
          "Al solicitar el servicio de transcripción manual nuestros expertos en idiomas tendrán acceso para corregir tu expediente. Los cambios de tu archivo actual se restablecerán y tu archivo se bloqueará hasta que las correcciones terminen.",
        description2: "Haz clic en OK para proceder a la comprobación",
      },
      BANNER: {
        firstStepTitle: "Controlla e rivedi la tua trascrizione.",
        firstStepDescTranscriber:
          "Puoi saltare questo, ma potrebbero esserci degli errori nei tuoi sottotitoli.",
        firstStepDesc:
          "Puoi saltarlo, ma potrebbero esserci degli errori nei tuoi sottotitoli. Puoi anche richiedere una revisione da uno dei nostri esperti linguistici.",
        secondStepTitle:
          "Hai finito di modificare? Premi Crea sottotitoli per allineare e formattare i sottotitoli.",
        secondStepDesc:
          "Potrai anche salvare la trascrizione per il tuo archivio.",
      },
      PRE_QC: {
        submitBtn: "Invia per controllo qualità...",
        dialogTitle: "Assistenza qualità",
        submitAnyway: "Consegna ugualmente",
        viewFix: "Visualizza & Correggi",
        spelling: " errori di ortografia",
        grammar: " errori di grammatica",
        cleanread: " errori di chiarezza",
        inaudible: " etichette inaudibile",
        cancel: "Annulla",
        submit: "Invia",
        confirmationTitle: "Invia per la revisione",
        confirmation:
          "Stai per inviare la trascrizione per la revisione. Questo passo non può essere annullato. Confermi l'invio della trascrizione?",
        emptyState: "Ricevi una verifica rapida del tuo documento.",
        runPreQCBtn: "Attiva Assistenza Qualità",
        spellingIssueFound: " errore di ortografia trovato",
        spellingIssuesFound: " errori di ortografia trovati",
        grammarIssueFound: " errore di grammatica trovato",
        grammarIssuesFound: " errori di grammatica trovati",
        cleanreadIssueFound: " errore di lettura pulita trovato",
        cleanreadIssuesFound: " errori di lettura pulita trovati",
        inaudibleIssueFound: " problema audio trovato",
        inaudibleIssuesFound: " problemi audio trovati",
        noSuggestion: "Nessun suggerimento",
        loadingSuggestions: "caricamento suggerimenti...",
        noIssueNotice: "Non sono stati riscontrati problemi. Ottimo lavoro!",
        formatting: " errori di formato",
        formattingIssueFound: " problema di formato",
        formattingIssuesFound: " problemi di formato",
        reportTitle: "Segnala falsi positivi",
        reportBtn: "Report false positive",
        reportComment: "Commenta",
        reportCommentPlaceholder: "Commenta qui",
        spellingInfoTooltip:
          "L'ortografia di alcune parole potrebbe essere errata. Considera la possibilità di correggerla o di segnalare i falsi positivi facendo clic sull'icona del bandiera.",
        inaudibleInfoTooltip:
          "Questa sezione è stata contrassegnata come incomprensibile. Assicurati di usare questo marcatore solo quando il discorso non è chiaro.",
        grammarInfoTooltip:
          "È stato riscontrato un errore grammaticale. Considera di controllare la maiuscola all'inizio della frase.",
        cleanReadInfoTooltip:
          "Sono state riscontrate alcune violazioni delle linee guida per il formato lettura. Considera la rimozione di parole ripetute, etichette non consentite e parole gergali.",
        formattingInfoTooltip:
          "Sono state riscontrate alcune violazioni delle linee guida sui sottotitoli. Considera la possibilità di aggiustare i limiti CPL/CPS, rimuovere i simboli non ammessi e avere clausole più brevi.",
        confirmationMessage:
          "Nota: A causa del numero di {totalIssues} problemi, l'Assistente di Qualità ha bisogno di più tempo. Sei sicuro di voler procedere?",
      },
    },
    CREATE_SUBTITLES: {
      title: "Aiuta il nostro algoritmo a fare il suo lavoro migliore",
      title1:
        "Usiamo l'apprendimento automatico per allineare i sottotitoli all'audio in un formato leggibile",
      title2:
        "Questa automazione significa che spendi meno tempo a fissare l'allineamento e la formattazione dei sottotitoli",
      title3:
        "Correggere gli errori nella tua trascrizione prima di creare i sottotitoli li rende ancora più accurati!",
      note1:
        "Tutti i file di sottotitoli in lingue diverse dall'inglese, dal tedesco e dall'olandese che sono più lunghi di 10 minuti dovrebbero essere sottoposti prima all'allineamento di Operations!",
      note2:
        "Una volta convertito questo file in sottotitoli, la trascrizione non sarà più disponibile. Per favore esporta la trascrizione ora se ne hai bisogno in seguito",
      note3:
        "Il file scomparirà temporaneamente dalla scheda dei lavori correnti, e apparirà di nuovo una volta che sarà completamente convertito",
      note4:
        "Il nostro algoritmo impiegherà qualche minuto per formattare i tuoi sottotitoli",
      note5: "Ti faremo sapere quando saranno pronti",
      confirmation1: "Ho finito di rivedere la mia trascrizione",
      rule1:
        "Massimo {maxLinesPerSegment} riga, {maxCharsPerLine} caratteri per riga, e {maxDisplayTimePerLine} secondi di tempo di schermo",
      rule2: "Numero massimo di linee",
      rule3: "Caratteri massimi per linea (CPL)",
      rule5: "Tempo massimo di visualizzazione per riga",
      customRules: "Regole personalizzate",
      customRulesDescription:
        "CPL, CPS, Durata minima/massima, Linee per sottotitolo, Intervallo fotogrammi, Conteggio",
      includeSpacesInCount: "Includi gli spazi nel conteggio dei caratteri",
      includeSpecialCharactersInCount:
        "Includere i caratteri speciali (#,&,%..) nel conteggio dei caratteri",
      maxCharsPerSecond: "Caratteri massimi al secondo",
      minSubtitleDuration: "Durata minima dei sottotitoli",
      maxSubtitleDuration: "Durata massima dei sottotitoli",
      minFrameGap: "Intervallo minimo tra i fotogrammi",
      netflixRules: "Regole Netflix",
      msgSuccess1:
        "Il processo è iniziato con successo, sarai reindirizzato alla piattaforma",
      msgSuccess2:
        "Il processo è stato avviato con successo, verrai reindirizzato alla dashboard",
      error1: "Si è verificato un errore sconosciuto, si prega di riprovare",
      button1: "Crea sottotitoli",
    },
    OriginalText: {
      title: "Show Original",
      transcript: "Transcript",
    },
    OriginalText: {
      title: "Mostra originale",
      transcript: "Trascrizione",
    },
    SPELLCHECK: {
      title: "Come attivare il controllo grammaticale?",
      clickHere: "Clicca qui",
      linkDesc:
        "per un'istruzione completa su come abilitare il controllo ortografico in Chrome",
      step1: "Vai nelle Impostazioni di Chrome",
      step2: "Espandi la Sezione Avanzata",
      step3: "Clicca su Lingua",
      step4: "Apri questo link in una nuova finestra Chrome",
      step5: "Nella sezione lingua, aggiungi la lingua desiderata",
      step6: "Riavvia Chrome per applicare le modifiche",
    },
    LOGIN: {
      title: "Bentornato!",
      subtitle: "Entra nel tuo account",
      alreadyQuestion: "Sei nuovo?",
      alreadyAction: "Iscriviti ora.",
      btn: "Entra",
      emailOrUsername: "Email o username",
      loginFormDescription:
        "Sei disconnesso/a o non hai l'autorizzazione per vedere questa pagina. Per favore, effettua nuovamente il login con l'account giusto.",
      errorLogin: "Combinazione email e password invalida",
      forgotPassword: "Password dimenticata",
      loggedinActionBtn: "Vai alle tue registrazioni",
    },
    PASSWORDRESET: {
      alreadyQuestion: "Hai bisogno di aiuto?",
      alreadyAction: "Contattaci al",
      actionLogin: "Entra",
      actionRegister: "Iscriviti",
      actionOr: "o",
      loggedinActionBtn: "Vai alle tue registrazioni",
    },
    PASSWORDRECOVER: {
      title: "Password dimenticata?",
      subtitle: "Creane una nuova qui.",
      btn: "Resetta password",
      hintUsername:
        "Questo è l'username che hai usato per creare il tuo account Amberscript.",
      or: "o",
      hintEmail:
        "Questa è l'email che hai usato per creare il tuo account Amberscript.",
    },
    PASSWORDCHANGE: {
      title: "Cambia password",
      subtitle:
        "Crea una nuova password per rientrare nel tuo account Amberscript",
      btn: "Conferma password",
    },
    EMAILVALIDATE: {
      loading: "Conferma in progresso...",
      inviteTitle: "Grazie per esserti registrato.",
      inviteSubtitle:
        "Abbiamo inviato una email per attivare il tuo account all'indirizzo",
      successTitle: "Fatto!",
      successSubtitle:
        "Grazie per aver confermato la tua email. Il tuo account è adesso attivo.",
      errorTitle: "Il link di verifica utilizzato non è corretto.",
      errorSubtitle: "Se il problema persiste contattaci a",
      loggedinActionBtn: "Vai alle tue registrazioni",
    },
    PLAN: {
      hour: "ora",
      select: "Seleziona",
      unsubscribeBtn: "Cancella iscrizione",
      unsubscribeMsg:
        "Sei sicuro di volere cancellare la tua iscrizione dall'abbonamento {{ plan }}?",
      unsubscribeOk: "Procedi",
      unsubscribeCancel: "Cancella",
      yourCurrent: "Il tuo abbonamento attuale",
      vatDisclaimer: "Prezzi IVA excl.",
      loading: "Caricando abbonamenti...",
      noResults: "Nessun abbonamento disponibile.",
      showPlans: "Mostra abbonamenti",
    },
    RECORDS: {
      upload: "Carica",
      name: "Nome",
      words: "Parole",
      length: "Durata",
      owner: "Proprietario",
      lastModified: "Modificato l'ultima volta",
      created: "Creato",
      more: "Di più",
      ariaMore: "Apri il menu dei comandi",
      share: "Condividi",
      delete: "Elimina",
      deleting: "Eliminando...",
      deletedSuccess: "{{ recordId }} eliminati con successo.",
      slicedFileMsg:
        "Questo file audio/video è stato tagliato a causa credito insufficiente.",
      invalid: "File non valido",
      errorGeneric: "Ci sono stati problemi con questa trascrizione.",
      loading: "Caricando i tuoi file...",
      noResults: "Nessuna trascrizione creata per ora",
      tipUploading: "Caricando...",
      tipOpen: "Trascrizione programmata",
      tipBusy: "Trascrivendo...",
      tipDone: "Pronta",
      tipError: "Errore",
      scheduled:
        "La trascrizione di {{ audioName }} è stata programmata. Ci vorrà qualche minuto.",
      transcribing: "Trascrivendo {{ audioName }}, {{ progress }}...",
      transcribed: "La trascrizione di {{ audioName }} è pronta.",
      uploadErrorServer:
        "Errore del server. Caricamento di {{ audioName }} fallito.",
      uploadError: "Caricamento di {{ audioName }} fallito.",
      uploadProgress: "Caricamento di {{ audioName }}:",
      uploadStart: "Caricamento di {{ audioName }} iniziato.",
      uploaded: "Caricamento di {{ audioName }} completato.",
      uploadhelpTitle: "Caricamento su Amberscript",
      uploadhelpTxt: "Amberscript supporta i seguenti formati audio/video:",
      uploadUnsupprtedFormatTitle: "Formato non supportato",
      uploadUnsupprtedFormatMsg:
        "Selezionare un file audio o video. Formati supportati:",
      uploadUnsupprtedFormatBtn: "OK",
      uploadConfirmTitle: "Pronto per la trascrizione!",
      uploadConfirmMsgTop:
        "Nota: assicurati che la qualità audio/video sia buona. Diamo per scontato che i tuoi file:",
      uploadconfirmList1: "Contengano discorsi pronunciati chiaramente",
      uploadconfirmList2: "Non abbiano rumori di fondo",
      uploadconfirmList3: "Non contengano discorsi in accenti marcati",
      uploadConfirmMsgBottom:
        "Se almeno uno di questi criteri non viene rispettato, Amberscript non può garantire trascrizioni di buona qualità.",
      uploadconfirmSelectASR: "Seleziona lingua audio",
      uploadConfirmOk: "Procedi",
      uploadConfirmCancel: "Cancella",
      unsupportedUploadTitle: "Caricamento non supportato",
      unsupportedUploadMsg:
        "Siamo spiacenti, il tuo motore di ricerca non supporta il caricamento di file audio/video.",
      unsupportedUploadBtn: "OK",
      deleteMsg: "Sei sicuro di voler eliminare '{{ name }}'?",
      deleteOk: "Conferma",
      deleteCancel: "Cancella",
      noCreditTitle: "Risparmia con un abbonamento Mensile",
      noCreditMsg:
        "Crediti insufficienti. Per continuare a caricare i tuoi file audio/video sottoscrivi un abbonamento.",
    },
    REGISTER: {
      title: "Ottieni la tua PROVA GRATUITA",
      subtitle: "Ottieni 30 minuti di trascrizione audio o video GRATIS",
      alreadyQuestion: "Hai già un account?",
      alreadyAction: "Entra",
      btn: "Registrati",
      forgotPassword: "Password dimenticata?",
      createPwd: "Scegli una password",
      confirmPwd: "Conferma password",
      loggedinActionBtn: "Vai alle tue registrazioni",
    },
    SHORTCUTS: {
      playPause: "Riproduci e metti in pausa il tuo file audio",
      rewind: "Manda indietro il tuo audio di 5 secondi",
      forward: "Manda avanti il tuo audio di 5 secondi",
      skip: "Salta una parola",
      reverse: "Annulla modifiche alla parola",
      highlight: "Evidenzia testo",
      undo: "Annulla",
      redo: "Ripeti",
      altClick: "Vai avanti con l'audio cliccando",
    },
    STATUS: {
      actionBtn: "Risparmia e passa al piano Mensile",
      remainingMsg: "Crediti trascrizione: {{ balance }}",
    },
    UPLOADS: {
      id: "ID",
      owner: "Proprietario",
      length: "Durata",
      date: "Data",
      noId: "Nessun ID specificato",
      loading: "Caricando registrazioni...",
      noResults: "Nessuna registrazione caricata",
    },
    TOUR: {
      back: "Indietro",
      close: "Chiudi",
      last: "Grazie!",
      next: "Avanti",
      skip: "Salta",
      dismissBtn: "Non mostrare più",
      EDITOR: {
        beginTitle: "Benvenuto!",
        begin:
          "In questa pagina ti mostreremo come utilizzare Amberscript a pieno potenziale.",
        altClick:
          "Porta avanti il tuo audio premendo il tasto alt mentre clicchi una determinata parola",
        play: "Con la funzione Play riproduci il file audio/video. Puoi interrompere e riprendere la riproduzione usando control + space sulla tastiera.",
        rewind:
          "Quando usi questo pulsante riavvolgi  di 5 secondi automaticamente l'audio. La combinazione di tasti di scelta rapida che puoi usare è control + r",
        highlight:
          "Con questo pulsante evidenzi passaggi nel tuo audio. Seleziona una parte di testo nella trascrizione, lo strumento evidenziatore apparirà trai comandi.",
        shortcuts:
          "Questo pulsante ti permette di scoprire moltissime combinazioni di tasti di scelta rapida, così potrai utilizzare Amberscript ancora più velocemente.",
        startTime:
          "Puoi regolare il segnatempo di inizio in ogni paragrafo per sincronizzarlo con la fonte originale, come ad esempio un video, cliccando qui.",
        end: "Tutto qui! Domande? Contattaci al <a href='mailto:info@amberscript.com'>info@amberscript.com</a>",
      },
    },
    REFERRAL: {
      title: "Aiuta i tuoi amici a risparmiare tempo",
      description:
        "Invita i tuoi amici ad usare Amberscript - per ogni persona che si iscrive, puoi ottenere fino a {{signupCreditMinutes}} minuti di credito.",
      terms: "Si applicano condizioni",
      enterEmail: "Inserisci indirizzo email",
      invitationSent: "Invito inviato. Invita quanti amici vuoi!",
      sendButton: "Spedisci Inviti",
      shareInviteLink: "Condividi inviti tramite un link",
      transcriptionCredit: "Il tuo credito di trascrizione",
      pending: "In attesa",
      earned: "Ottenuti",
      copy: "Copia",
      copied: "Copiato!",
      errorTitle: "Codice scaduto",
      errorDescription:
        "Ti informeremo di nuove promozioni via email e social media.",
    },
    REALIGN: {
      btnName: "Riallinea i segnatempo",
      dialogTitle: "Allineamento in corso",
      dialogContent:
        "Miglioramento dei segnatempo della trascrizione, per favore attendere...",
    },
    TRANSACTIONS: {
      id: "ID",
      owner: "Proprietario",
      seconds: "Secondi",
      date: "Data",
      noId: "ID non specificato",
      loading: "Caricando transazioni...",
      noResults: "Nessuna transazione da mostrare",
    },
    THANKS: {
      mainMsg: "Grazie!",
      summaryTitle: "Riepilogo",
    },
    SCRAMBLED_DIALOG: {
      title: "Ops! Si è verificato un errore nel tuo documento",
      description1:
        "Il testo all’interno del tuo documento è stato accidentalmente rimescolato.",
      description2:
        "Come risultato, il tuo documento deve essere ripristinato allo stato precedente. La tua ultima modifica sarà persa perché la pagina deve essere aggiornata.",
      titleFeedback:
        "Per favore, facci sapere quale azione hai intrapreso prima di vedere questa finestra di dialogo in modo che possiamo rimediare al problema.",
      descriptionButton: "La pagina verrà aggiornata automaticamente",
    },
    COUNTRIES: {
      AFG: "Afghanistan",
      ALA: "Åland Islands",
      ALB: "Albania",
      DZA: "Algeria",
      ASM: "American Samoa",
      AND: "Andorra",
      AGO: "Angola",
      AIA: "Anguilla",
      ATA: "Antarctica",
      ATG: "Antigua e Barbuda",
      ARG: "Argentina",
      ARM: "Armenia",
      ABW: "Aruba",
      AUS: "Australia",
      AUT: "Austria",
      AZE: "Azerbaijan",
      BHS: "Bahamas",
      BHR: "Bahrain",
      BGD: "Bangladesh",
      BRB: "Barbados",
      BLR: "Bielorussia",
      BEL: "Belgio",
      BLZ: "Belize",
      BEN: "Benin",
      BMU: "Bermuda",
      BTN: "Bhutan",
      BOL: "Bolivia, Stato Plurinazionale della",
      BES: "Bonaire",
      BIH: "Bosnia e Herzegovina",
      BWA: "Botswana",
      BVT: "Isola Bouvet",
      BRA: "Brasile",
      IOT: "Territorio britannico dell'Oceano Indiano",
      BRN: "Brunei Darussalam",
      BGR: "Bulgaria",
      BFA: "Burkina Faso",
      BDI: "Burundi",
      KHM: "Cambodia",
      CMR: "Cameroon",
      CAN: "Canada",
      CPV: "Cape Verde",
      CYM: "Isole Cayman ",
      CAF: "Repubblica Centrale Africana ",
      TCD: "Chad",
      CHL: "Chile",
      CHN: "Cina",
      CXR: "Isola di Natale",
      CCK: "Isole Cocos (Keeling) ",
      COL: "Colombia",
      COM: "Comoros",
      COG: "Congo",
      COD: "Congo, Repubblica Democratica del",
      COK: "Isole Cook",
      CRI: "Costa Rica",
      CIV: "Costa d'Avorio",
      HRV: "Croazia",
      CUB: "Cuba",
      CUW: "Curaçao",
      CYP: "Cipro",
      CZE: "Repubblica Ceca",
      DNK: "Danimarca",
      DJI: "Djibouti",
      DMA: "Dominica",
      DOM: "Repubblica Dominicana ",
      ECU: "Ecuador",
      EGY: "Egitto",
      SLV: "El Salvador",
      GNQ: "Guinea Equatoriale",
      ERI: "Eritrea",
      EST: "Estonia",
      ETH: "Ethiopia",
      FLK: "Isole Falkland (Malvinas)",
      FRO: "Isole Faroe",
      FJI: "Fiji",
      FIN: "Finlandia",
      FRA: "Francia",
      GUF: "Guiana Francese",
      PYF: "Polynesia Francese",
      ATF: "Territori francesi del Sud",
      GAB: "Gabon",
      GMB: "Gambia",
      GEO: "Georgia",
      DEU: "Germania",
      GHA: "Ghana",
      GIB: "Gibilterra",
      GRC: "Grecia",
      GRL: "Groenlandia",
      GRD: "Grenada",
      GLP: "Guadeloupe",
      GUM: "Guam",
      GTM: "Guatemala",
      GGY: "Guernsey",
      GIN: "Guinea",
      GNB: "Guinea-Bissau",
      GUY: "Guyana",
      HTI: "Haiti",
      HMD: "Isola Heard e Isole McDonald",
      VAT: "Stato del Vaticano",
      HND: "Honduras",
      HKG: "Hong Kong",
      HUN: "Ungheria",
      ISL: "Islanda",
      IND: "India",
      IDN: "Indonesia",
      IRN: "Iran, Repubblica islamica del",
      IRQ: "Iraq",
      IRL: "Islanda",
      IMN: "Isola di Man",
      ISR: "Israele",
      ITA: "Italia",
      JAM: "Jamaica",
      JPN: "Giappone",
      JEY: "Jersey",
      JOR: "Jordan",
      KAZ: "Kazakhstan",
      KEN: "Kenya",
      KIR: "Kiribati",
      PRK: "Corea, Repubblica Popolare Democratica di",
      KOR: "Corea, Repubblica di",
      KWT: "Kuwait",
      KGZ: "Kyrgyzstan",
      LAO: "Repubblica Popolare Democratica del Lao",
      LVA: "Lettonia",
      LBN: "Libano",
      LSO: "Lesotho",
      LBR: "Liberia",
      LBY: "Libia",
      LIE: "Liechtenstein",
      LTU: "Lituania",
      LUX: "Lussemburgo",
      MAC: "Macao",
      MKD: "Macedonia, precedentemente Repubblica Iugoslavia di",
      MDG: "Madagascar",
      MWI: "Malawi",
      MYS: "Malesia",
      MDV: "Maldive",
      MLI: "Mali",
      MLT: "Malta",
      MHL: "Isole Marshall",
      MTQ: "Martinique",
      MRT: "Mauritania",
      MUS: "Mauritius",
      MYT: "Mayotte",
      MEX: "Messico",
      FSM: "Micronesia, Stato federale di",
      MDA: "Moldova, Repubblica di",
      MCO: "Principato di Monaco",
      MNG: "Mongolia",
      MNE: "Montenegro",
      MSR: "Montserrat",
      MAR: "Marocco",
      MOZ: "Mozambico",
      MMR: "Myanmar",
      NAM: "Namibia",
      NRU: "Nauru",
      NPL: "Nepal",
      NLD: "Paesi Bassi",
      NCL: "Nuova Caledonia",
      NZL: "Nuova Zealanda",
      NIC: "Nicaragua",
      NER: "Niger",
      NGA: "Nigeria",
      NIU: "Niue",
      NFK: "Isole Norfolk",
      MNP: "Isole Northern Mariana",
      NOR: "Norvegia",
      OMN: "Oman",
      PAK: "Pakistan",
      PLW: "Palau",
      PSE: "Territorio Palestinese, Occupato",
      PAN: "Panama",
      PNG: "Papua Nuova Guinea",
      PRY: "Paraguay",
      PER: "Peru",
      PHL: "Filippine",
      PCN: "Pitcairn",
      POL: "Polonia",
      PRT: "Portogallo",
      PRI: "Puerto Rico",
      QAT: "Qatar",
      REU: "Réunion",
      ROU: "Romania",
      RUS: "Federazione Russa",
      RWA: "Rwanda",
      BLM: "San Barthélemy",
      SHN: "Sant'Elena, Ascensione e Tristan da Cunha",
      KNA: "Saint Kitts and Nevis",
      LCA: "Saint Lucia",
      MAF: "Saint Martin (French part)",
      SPM: "Saint Pierre and Miquelon",
      VCT: "Saint Vincent and the Grenadines",
      WSM: "Samoa",
      SMR: "San Marino",
      STP: "Sao Tome e Principe",
      SAU: "Arabia Saudita",
      SEN: "Senegal",
      SRB: "Serbia",
      SYC: "Seychelles",
      SLE: "Sierra Leone",
      SGP: "Singapore",
      SXM: "Sant Maarten (parte Olandese)",
      SVK: "Slovacchia",
      SVN: "Slovenia",
      SLB: "Isole Solomon",
      SOM: "Somalia",
      ZAF: "Sud Africa",
      SGS: "Georgia del Sud e Isole Sandwich del Sud",
      SSD: "Sudan del Sud",
      ESP: "Spagna ",
      LKA: "Sri Lanka",
      SDN: "Sudan",
      SUR: "Suriname",
      SJM: "Svalbard e Jan Mayen",
      SWZ: "Swaziland",
      SWE: "Svezia",
      CHE: "Svizzera",
      SYR: "Repubblica araba della Siria Arab Republic",
      TWN: "Taiwan, provincia della Cina",
      TJK: "Tajikistan",
      TZA: "Tanzania, Repubblica unita dalla",
      THA: "Thailandia",
      TLS: "Timor-Leste",
      TGO: "Togo",
      TKL: "Tokelau",
      TON: "Tonga",
      TTO: "Trinidad e Tobago",
      TUN: "Tunisia",
      TUR: "Turchia",
      TKM: "Turkmenistan",
      TCA: "Isole Turks e Caicos",
      TUV: "Tuvalu",
      UGA: "Uganda",
      UKR: "Ucraina",
      ARE: "Emirati Arabi Uniti",
      GBR: "Regno Unito",
      USA: "Stati Uniti",
      UMI: "Stati Uniti Isole periferiche minori",
      URY: "Uruguay",
      UZB: "Uzbekistan",
      VUT: "Vanuatu",
      VEN: "Venezuela, Repubblica Bolivariana del",
      VNM: "Viet Nam",
      VGB: "Isole Vergini, Regno Unito",
      VIR: "Isole Vergin, U.S.",
      WLF: "Wallis e Futuna",
      ESH: "Sahara dell'Est",
      YEM: "Yemen",
      ZMB: "Zambia",
      ZWE: "Zimbabwe",
    },
    GLOSSARY: {
      title: "Dizionario",
      names: "Nomi",
      terms: "Parole",
      notFound:
        "Il dizionario non è stato trovato. La prossima volta, prima di caricare il file, creare un dizionario per migliorare l'accuratezza del testo.",
    },
    MACHINE_TRANSLATIONS: {
      direct: "Automatica",
      perfect: "Professionale",
    },
    EDITOR_LANGUAGE: {
      en: "Inglese",
      "en-us": "Inglese (Stati Uniti)",
      "en-uk": "Inglese (Regno Unito)",
      nl: "Olandese",
      de: "Tedesco",
      fr: "Francese",
      es: "Spagnolo",
      it: "Italiano",
      pt: "Portoghese",
      da: "Danese",
      fi: "Finlandese",
      no: "Norvegese (Bokmål)",
      sv: "Svedese",
      pl: "Polacco",
      ro: "Rumeno",
      uk: "Ucraino",
      ru: "Russo",
      bg: "Bulgaro",
      cs: "Ceco",
      el: "Greco",
      et: "Estone",
      hu: "Ungherese",
      ja: "Giapponese",
      ko: "Coreano",
      lt: "Lituano",
      lv: "Lettone",
      sk: "Slovacco",
      sl: "Sloveno",
      tr: "Turco",
      zh: "Cinese",
    },
  },
};
